
import React, { FC, useState } from 'react';
import { Button } from '@material-ui/core';

// export function checkIfNull<R> ( item: R ) {
//     if(item === null || item === undefined || (typeof item === 'string' && item.length === 0)) {
//         return false;
//     }else {
//         return true;
//     }
// }; 
export const checkIfNull = <R,>( item: R ): boolean => {
    if(item === null || item === undefined || (typeof item === 'string' && item.length === 0)) {
        return false;
    }else {
        return true;
    }
}; 

export const checkIfUrl = <R,>( item: R ): boolean => {
    let url: URL;

    try {
        url = new URL(typeof item === 'string' ? item : '')
    } catch(_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';

}

export const dateCleaner = ( item: string )  => {
    if(item!?.length > 0){
        return new Date(item).toDateString()
    }else{
        return ''
    }
};

export const timeCleaner = ( item: string )  => {
    return new Date(item).toLocaleTimeString()
};

export const separator = ( name : string ) => {
    let arr = name!?.split('').map( item => {
        if( item === item!?.toUpperCase() ) return ' ' + item;
        else return item;
    } ).toString().replace(/,/g, '');
    return arr!?.charAt(0).toUpperCase() + arr!?.slice(1).toLowerCase();
};

interface IVB {
    vehicleOffenseId: number;
    generateInvoiceId: (offenceId: object) => void;
    isGInvoiceLoad: boolean;
    pageNumber: number;
    pageSize: number;
    getAllVehicleOffencePayment: ( pageNumber?: number, pageSize?: number ) => void; 
}

export const InvoiceButton:FC<IVB> = (props) => {
    const { vehicleOffenseId = 0, generateInvoiceId, isGInvoiceLoad, pageNumber, pageSize, getAllVehicleOffencePayment } = props;
    const [ isClicked, setIsClicked ] = useState( () => false);

    return(
        <Button variant="contained" color="primary" 
        onClick={() => {generateInvoiceId({offenseId :vehicleOffenseId}); setIsClicked(true);
            setTimeout(() => {
                setIsClicked(false)
                getAllVehicleOffencePayment(pageNumber, pageSize)
            }, 2000)
        }} >
            {(isGInvoiceLoad && isClicked) ? 'Loading...' : 'Get invoice Id'}
        </Button>
    )
}

export const checkIfVid =  <R,>( item: R ): boolean => {
    if (typeof item === 'string' && item.length > 0) {
        if( item.substring(item.lastIndexOf('.')).includes('mp4') ) {
            return true
        }else{
            return false
        }
    }return false
};
