import React from "react";

export function CoreValuesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="666.667"
      height="666.667"
      version="1.1"
      viewBox="0 0 666.667 666.667"
      xmlSpace="preserve"
    >
      <defs>
        <clipPath id="clipPath98" clipPathUnits="userSpaceOnUse">
          <path d="M3530.7 1055.97h508.9c484.04 0 876.44 392.39 876.44 876.43 0 484.04-392.4 876.43-876.44 876.43h-508.9c-484.03 0-876.42-392.39-876.42-876.43 0-484.04 392.39-876.43 876.42-876.43"></path>
        </clipPath>
        <clipPath id="clipPath110" clipPathUnits="userSpaceOnUse">
          <path d="M3530.7 1055.97h508.9c484.04 0 876.44 392.39 876.44 876.43 0 484.04-392.4 876.43-876.44 876.43h-508.9c-484.03 0-876.42-392.39-876.42-876.43 0-484.04 392.39-876.43 876.42-876.43"></path>
        </clipPath>
        <clipPath id="clipPath116" clipPathUnits="userSpaceOnUse">
          <path d="M4053.44 1059.73l-.03.39h5.02l-4.99-.39"></path>
        </clipPath>
        <clipPath id="clipPath126" clipPathUnits="userSpaceOnUse">
          <path d="M3530.7 1055.97h508.9c484.04 0 876.44 392.39 876.44 876.43 0 484.04-392.4 876.43-876.44 876.43h-508.9c-484.03 0-876.42-392.39-876.42-876.43 0-484.04 392.39-876.43 876.42-876.43"></path>
        </clipPath>
        <clipPath id="clipPath240" clipPathUnits="userSpaceOnUse">
          <path d="M1119.71 1062.41h602.81c573.36 0 1038.16 464.81 1038.16 1038.17 0 573.37-464.8 1038.17-1038.16 1038.17h-602.81c-573.366 0-1038.17-464.8-1038.17-1038.17 0-573.36 464.804-1038.17 1038.17-1038.17"></path>
        </clipPath>
      </defs>
      <g transform="matrix(1.33333 0 0 -1.33333 0 666.667)">
        <g transform="scale(.1)">
          <path
            fill="#fff"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M0 0h5000v5000H0z"
          ></path>
          <path
            fill="#f6f8fd"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3361.59 3395.18c-228.24 0-413.26 185.03-413.26 413.27 0 228.24 185.02 413.26 413.26 413.26 228.24 0 413.27-185.02 413.27-413.26 0-228.24-185.03-413.27-413.27-413.27zm668.79 277.19v272.16l-165.13 28a526.437 526.437 0 01-31.49 76.03l96.96 136.57-192.44 192.44-136.57-96.96a525.94 525.94 0 01-76.04 31.5l-28 165.12h-272.15l-28.01-165.12a525.94 525.94 0 01-76.04-31.5l-136.56 96.96-192.44-192.44 96.96-136.57a525.354 525.354 0 01-31.5-76.03l-165.13-28v-272.16l165.13-28a525.94 525.94 0 0131.5-76.04l-96.96-136.57 192.44-192.44 136.56 96.96a527.026 527.026 0 0176.04-31.49l28.01-165.13h272.15l28 165.13a527.026 527.026 0 0176.04 31.49l136.57-96.96 192.44 192.44-96.96 136.57a527.026 527.026 0 0131.49 76.04l165.13 28"
          ></path>
          <path
            fill="#dce3f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3361.59 3452.57c-196.54 0-355.88 159.33-355.88 355.88 0 196.55 159.34 355.88 355.88 355.88s355.88-159.33 355.88-355.88c0-196.55-159.34-355.88-355.88-355.88"
          ></path>
          <path
            fill="#fff"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3233.8 3787.48l88.89-88.89 166.7 166.69-35.36 35.35-131.33-131.33-53.55 53.54-35.35-35.36"
          ></path>
          <path
            fill="#f0f4fb"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1119.71 1062.41h602.81c573.36 0 1038.16 464.81 1038.16 1038.17 0 573.37-464.8 1038.17-1038.16 1038.17h-602.81c-573.366 0-1038.17-464.8-1038.17-1038.17 0-573.36 464.804-1038.17 1038.17-1038.17"
          ></path>
          <path
            fill="#f0f4fb"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2722.93 1053.75l-131.31 305.05-131.3 305.05-173.74-173.75-173.74-173.74 305.04-131.3 305.05-131.31"
          ></path>
          <path
            fill="#f0f4fb"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1915.92 3967.47v5h-203.41v198.41h198.41v-203.41h5v5-5h5v213.41h-218.41v-218.41h218.41v5h-5"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2302.96 4098.63h-287.24v27.85h287.24v-27.85"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2130.79 4016.86h-115.07v27.85h115.07v-27.85"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2351.96 4016.86h-190.57v27.85h190.57v-27.85"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2471.76 4098.63h-119.8v27.85h119.8v-27.85"
          ></path>
          <path
            fill="#f25376"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1735.97 4088.46c22.3-13.77 44.6-27.55 66.9-41.32l13.48-8.33 7.58 13.92c32.98 60.66 76.61 117.79 125.81 166.45l-21.09 21.33c-51.24-50.69-96.56-109.99-131.08-173.44l13.18-7.17 7.89 12.76c-22.3 13.78-44.6 27.55-66.9 41.33l-15.77-25.53"
          ></path>
          <path
            fill="#f0f4fb"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1915.92 3618.46v5h-203.41v198.41h198.41v-203.41h5v5-5h5v213.41h-218.41v-218.41h218.41v5h-5"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2302.96 3749.62h-287.24v27.86h287.24v-27.86"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2130.79 3667.86h-115.07v27.85h115.07v-27.85"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2351.96 3667.86h-190.57v27.85h190.57v-27.85"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2471.76 3749.62h-119.8v27.86h119.8v-27.86"
          ></path>
          <path
            fill="#f25376"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1735.97 3739.46c22.3-13.78 44.6-27.55 66.9-41.33l13.48-8.33 7.58 13.93c32.98 60.65 76.61 117.78 125.81 166.44l-21.09 21.33c-51.24-50.68-96.56-109.99-131.08-173.44l13.18-7.17 7.89 12.76c-22.3 13.78-44.6 27.55-66.9 41.33l-15.77-25.52"
          ></path>
          <path
            fill="#f0f4fb"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1915.92 3269.45v5h-203.41v198.41h198.41v-203.41h5v5-5h5v213.41h-218.41v-218.41h218.41v5h-5"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2302.96 3400.62h-287.24v27.85h287.24v-27.85"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2130.79 3318.85h-115.07v27.85h115.07v-27.85"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2351.96 3318.85h-190.57v27.85h190.57v-27.85"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2471.76 3400.62h-119.8v27.85h119.8v-27.85"
          ></path>
          <path
            fill="#f25376"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1735.98 3390.45c22.29-13.77 44.59-27.55 66.89-41.32l13.48-8.33 7.58 13.92c32.98 60.66 76.61 117.78 125.81 166.45l-21.09 21.33c-51.24-50.69-96.56-110-131.08-173.44l13.18-7.17 7.89 12.76c-22.3 13.78-44.6 27.55-66.9 41.32l-15.76-25.53"
          ></path>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1616.2 3172.65v1264.58h921.39v-1324.6h-693.88v-7.5h701.38v1339.6H1608.7V3172.65h7.5"
          ></path>
          <g transform="scale(10)">
            <text
              transform="matrix(1 0 0 -1 188.01 427.991)"
              fill="#342b6f"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              fontFamily="Montserrat"
              fontSize="10.566"
              fontVariant="normal"
              fontWeight="bold"
              writingMode="lr-tb"
            >
              <tspan
                x="0 7.1215515 13.577438 21.882393 25.242413 32.554153"
                y="0"
              >
                ETHICS
              </tspan>
            </text>
          </g>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2280.77 4411.31H1873c-17.39 0-31.5 14.1-31.5 31.5v39.86c0 17.39 14.11 31.49 31.5 31.49h407.77c17.4 0 31.5-14.1 31.5-31.49v-39.86c0-17.4-14.1-31.5-31.5-31.5"
          ></path>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2076.88 4514.16c-15.29 0-27.69 12.41-27.69 27.7s12.4 27.69 27.69 27.69c15.3 0 27.7-12.4 27.7-27.69 0-15.29-12.4-27.7-27.7-27.7zm0 91c-39.32 0-71.2-31.88-71.2-71.21v-71.21h142.42v71.21c0 39.33-31.88 71.21-71.22 71.21"
          ></path>
          <path
            fill="#dce3f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3073.52 2912.23h-2.5c-.01-116.33-47.15-221.62-123.38-297.85-76.23-76.23-181.52-123.37-297.85-123.37-116.32 0-221.62 47.14-297.85 123.37-76.23 76.23-123.37 181.52-123.38 297.85.01 116.32 47.15 221.61 123.38 297.85 76.23 76.23 181.53 123.37 297.85 123.37 116.33 0 221.62-47.14 297.85-123.37 76.23-76.24 123.37-181.53 123.38-297.85h5c-.01 235.4-190.83 426.22-426.23 426.22s-426.22-190.82-426.23-426.22c.01-235.4 190.83-426.22 426.23-426.22s426.22 190.82 426.23 426.22h-2.5"
          ></path>
          <path
            fill="#dce3f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2922.25 2912.23h-2.5c0-116.58-30.41-222.08-79.41-298.27-49.03-76.22-116.43-122.97-190.55-122.95-74.12-.02-141.52 46.73-190.55 122.95-49 76.19-79.41 181.69-79.41 298.27 0 116.57 30.41 222.07 79.41 298.26 49.03 76.23 116.43 122.97 190.55 122.96 74.12.01 141.52-46.73 190.55-122.96 49-76.19 79.41-181.69 79.41-298.26h5c-.01 117.44-30.59 223.8-80.2 300.97-49.58 77.13-118.4 125.24-194.76 125.25-76.35-.01-145.18-48.12-194.76-125.25-49.61-77.17-80.19-183.53-80.2-300.97.01-117.45 30.59-223.8 80.2-300.97 49.58-77.13 118.41-125.24 194.76-125.25 76.36.01 145.18 48.12 194.76 125.25 49.61 77.17 80.19 183.52 80.2 300.97h-2.5"
          ></path>
          <path
            fill="#dce3f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2996.87 3156.63c-98.81-30.31-218.34-48.06-347.08-48.06-128.75 0-248.27 17.75-347.1 48.06l-1.47-4.78c99.38-30.48 219.36-48.27 348.57-48.28 129.2.01 249.18 17.8 348.55 48.28l-1.47 4.78"
          ></path>
          <path
            fill="#dce3f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2302.7 2667.82c98.82 30.32 218.35 48.06 347.09 48.06 127.83 0 246.57-17.49 344.98-47.41l1.46 4.78c-98.96 30.09-218.15 47.63-346.44 47.63-129.2 0-249.18-17.8-348.56-48.28l1.47-4.78"
          ></path>
          <path
            fill="#dce3f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2226.06 2909.73h847.461v5H2226.06z"
          ></path>
          <path
            fill="#dce3f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2647.29 2488.51h5v847.438h-5z"
          ></path>
          <path
            fill="#9b9fd8"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2649.79 2839.61c-40.71 0-73.71 33-73.71 73.71 0 40.71 33 73.71 73.71 73.71 40.71 0 73.72-33 73.72-73.71 0-40.71-33.01-73.71-73.72-73.71zm180.43 37v73.42l-44.55 7.56c-2.31 7.1-5.15 13.95-8.49 20.51l26.15 36.85-51.91 51.92-36.85-26.16c-6.56 3.34-13.42 6.18-20.51 8.49l-7.56 44.55h-73.42l-7.55-44.55c-7.1-2.31-13.96-5.15-20.52-8.49l-36.85 26.16-51.91-51.92 26.15-36.85c-3.34-6.56-6.18-13.41-8.49-20.51l-44.55-7.56v-73.42l44.55-7.56c2.31-7.09 5.15-13.95 8.49-20.51l-26.15-36.85 51.91-51.91 36.85 26.16c6.56-3.35 13.42-6.19 20.52-8.5l7.55-44.55h73.42l7.56 44.55a141.41 141.41 0 0120.51 8.5l36.85-26.16 51.91 51.91-26.15 36.85c3.34 6.56 6.18 13.42 8.49 20.51l44.55 7.56"
          ></path>
          <path
            fill="#e0e4f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3533.13 1055.97h508.9c484.04 0 876.43 392.39 876.43 876.43 0 484.04-392.39 876.43-876.43 876.43h-508.9c-484.04 0-876.42-392.39-876.42-876.43 0-484.04 392.38-876.43 876.42-876.43"
          ></path>
          <path
            fill="#e0e4f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2786.46 936.051l490.09 292.139-167.47 128.46-167.47 128.47-155.15-549.069"
          ></path>
          <g>
            <g
              fill="#6467ba"
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              clipPath="url(#clipPath98)"
            >
              <path d="M3948.93 1978.15l171.9-918.03h481.88l-191.98 918.03h-461.8"></path>
              <path d="M3602.05 1978.15l88.73-918.03h534.6l-151.61 918.03h-471.72"></path>
            </g>
          </g>
          <g>
            <g clipPath="url(#clipPath110)">
              <g clipPath="url(#clipPath116)">
                <path
                  fill="#e7eaf7"
                  fillOpacity="1"
                  fillRule="nonzero"
                  stroke="none"
                  d="M4053.41 1059.73h5.02v.39h-5.02z"
                ></path>
              </g>
            </g>
          </g>
          <g>
            <g
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              clipPath="url(#clipPath126)"
            >
              <path
                fill="#8787cb"
                d="M4058.43 1060.12h-5.02l-39.06 508.06 9.97.77 39.09-508.45-4.98-.38"
              ></path>
              <path
                fill="#151312"
                d="M4167.93 1060.12l-66.14 499.34s-89.87 60.32-152.86 108.18c0 0 34.96-373.71 219-607.52"
              ></path>
              <path
                fill="#e7eaf7"
                d="M4329.77 1978.15h-5.1l4.92.96.18-.96"
              ></path>
              <path
                fill="#8787cb"
                d="M4476.45 1172.43l-156.68 804.77 4.9.95h5.1l155.83-800.38c-3.04-1.8-6.09-3.58-9.15-5.34"
              ></path>
              <path
                fill="#151312"
                d="M3607.93 1913.62h815.52v7.5h-815.52z"
              ></path>
              <path
                fill="#151312"
                d="M3781.55 1977.14c.03-.11 14.15-50.3 39.73-107.67 25.66-57.35 62.45-121.95 109.24-151.01l7.18-4.44-48.6 264.81-7.38-1.35 47.08-256.51 3.69.68 1.97 3.19c-44.18 27.2-81.06 90.86-106.33 147.69-12.67 28.41-22.52 55.1-29.2 74.67a814 814 0 00-7.59 23.34c-1.71 5.56-2.57 8.62-2.57 8.63l-7.22-2.03"
              ></path>
              <path
                fill="#151312"
                d="M4164.24 1977.51l17.72-100.94 7.39 1.3-17.73 100.93"
              ></path>
              <path
                fill="#151312"
                d="M3653.81 1977.51l17.72-100.94 7.39 1.3-17.73 100.93"
              ></path>
            </g>
          </g>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3491.44 3526.41s-109.48 75.49-125.94 156.9c-9.75 48.2 71.27 125.62 71.27 125.62s-36.66 87.56-25.6 101.89c11.06 14.32 181.81-32.86 181.81-32.86s10.73 47.68 25.94 53.41c15.2 5.73 173.27-80.66 173.27-80.66s5.18 48.02 14.32 49.12c9.14 1.1 20.56-56.27 20.56-56.27s63.49 36.1 80.84 28.36c17.35-7.73-44.76-60.58-44.76-60.58s62.36-7.17 65.54-22.36c3.18-15.2-30.43-270.89-30.43-270.89l-406.82 8.32"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3167.95 2091s186.39-86.18 387.29-23.79c0 0-151.31 124.21-387.29 23.79"
          ></path>
          <path
            fill="#f689a6"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3290.41 1417.15l152.72-.2 78.82 763.95s-111.5 137.5-162.38 144.31c0 0-64.35-37.61-77.96-91.37-13.6-53.77 8.8-816.69 8.8-816.69"
          ></path>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3412.71 3153.82c-120.67-16.18-182.85-962.16-182.85-962.16l323.41-48.36s-37.17 1024.39-140.56 1010.52"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3555.24 2067.21s51.67 50.35 3.78 96.94c-47.22 45.95-309.65 46.04-309.65 46.04s-80.22-4.89-56.42-65.6c0 0-38.93-11.16-25-53.59 0 0 228.82 78 387.29-23.79"
          ></path>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4413.16 1978.15h-808.68s-108.04 91.27-102.19 147.31c8.55 81.67-11.47 612.34-89.58 1028.36-4.32 22.96 252.5 29.66 252.5 29.66l194.59-10.66c92.91-16.13 256.7-63.99 313.69-144.96 40.2-57.14 158.02-600.22 226.43-889.16 0 0 55.32-53.24 66.2-91.16 10.87-37.93-52.96-69.39-52.96-69.39"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3440.13 2961.54c0-.01.78-6.38 2.17-17.93 9.71-80.85 48.81-415.13 53.08-583.78l7.5.19c-4.92 193.53-55.31 602.37-55.31 602.44l-7.44-.92"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3613.4 3181.46c.01-.18 4.36-67.54 28.96-149.41 24.62-81.85 69.44-178.4 151.11-236.56l4.35 6.11c-79.51 56.52-123.94 151.54-148.28 232.61-12.17 40.51-19.35 77.49-23.47 104.32-2.06 13.41-3.36 24.29-4.14 31.8-.79 7.51-1.05 11.6-1.05 11.61l-7.48-.48"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4104.4 2324.19c112.44 182.19 46.97 336.67 46.97 336.67l-75.18-300.94 28.21-35.73"
          ></path>
          <path
            fill="#f689a6"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3131.35 2455.35s-144.09 39.15-180.84 38.33c-36.75-.82-69.16-43.01-69.16-43.01s-107.78-110.1-116.73-127.19c-26.29-50.18 22.64-27.22 22.64-27.22s-9.95-26.96 19.97-28.89c25.64-1.66 37.06-1.68 37.06-1.68s-3.31-12.98 19.86-21.24c23.16-8.27 172.11-20.87 251.54 65.19l15.66 145.71"
          ></path>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4020.7 3048.04c-59.8 9.83-188.84-554.34-188.84-554.34s183.87-153.64 213.67-220.4c0 0 282.41 724.25-24.83 774.74"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3764.77 2493.7s70.57-228.11 292.34-255.15c0 0-78.26 239.43-292.34 255.15"
          ></path>
          <path
            fill="#f689a6"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3115.69 2309.64l15.66 145.71 779.59 26.75s129.01-121.21 132.11-172.46c0 0-42.21-61.44-96.82-71.09-54.62-9.65-830.54 71.09-830.54 71.09"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3764.77 2493.7s-1.4 72.13 65.41 71.65c65.89-.49 252.71-184.77 252.71-184.77s53.65-59.84-5.92-86.33c0 0 19.86-35.29-19.86-55.7 0 0-108.05 216.26-292.34 255.15"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3849.2 2561.59c0 .02 1.89 6.55 5.25 18.12 11.74 40.48 41.4 142.37 70.54 239.56 14.56 48.59 29 96 40.99 133.96 11.97 37.91 21.59 66.56 26.25 76.98 3.5 7.84 9.04 12.98 15.7 16.32 6.64 3.33 14.42 4.75 21.91 4.75 10.44.04 20.36-2.87 25.15-6.21l4.23 6.2c-6.77 4.56-17.59 7.46-29.38 7.51-8.41-.01-17.3-1.56-25.27-5.54-7.96-3.96-14.98-10.47-19.2-19.98-5.7-12.85-17.34-48.24-31.83-94.62-43.4-138.98-111.54-374.95-111.55-374.97l7.21-2.08"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4253.99 2187.92c.01 0 1.6-.12 4.53-.43 10.28-1.09 36.67-4.43 64.66-12.31 27.99-7.84 57.5-20.39 73.94-38.97l5.6 4.98c-20.93 23.41-57.87 36.59-89.62 44.38-31.76 7.73-58.4 9.82-58.52 9.83l-.59-7.48"
          ></path>
          <path
            fill="#975565"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3763.92 982.441l43.92-507.859s-490.67-170.812-874.46-12.434l-4.42 483.864 834.96 36.429"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3783.12 764.91l3.36-43.41s-353.64-124.34-427.85-123.68c-74.22.672-427.28 86.828-427.28 86.828l-.63 68.454 852.4 11.808"
          ></path>
          <path
            fill="#9d4f6a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3486.83 1304.08l-372.05-24.68-61.93-193.09 65.66-17.01 23.15 159.41 306.95 29.94 74.08-184.01 70.48 8.1-106.34 221.34"
          ></path>
          <path
            fill="#9d4f6a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3783.12 764.91l-30.77 348.66-819.81-11.21-1.82-349.258s296.19-124.2 432.41-115.793c136.22 8.421 419.99 127.601 419.99 127.601"
          ></path>
          <path
            fill="#f9c478"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3425.08 688.43l8.12-95.59s-51.19-44.41-101.31-9.078l-7.29 101.187 100.48 3.481"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2932.54 1102.36l-58.26-24.08-30.87-582.721 89.97-33.411-.84 640.212"
          ></path>
          <path
            fill="#f689a6"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3290.41 1417.15s-47.48-73.98-53.39-83.5c-5.91-9.53 33.12-113.01 55.05-115.87 21.93-2.87 24.38 75 24.38 75l106.69 7.07s43.5 56.01 19.99 117.1l-152.72.2"
          ></path>
          <path
            fill="#f689a6"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3316.45 1292.78s12.4 21.67 28.62 28.23c16.23 6.56 30.3-24.32 30.3-24.32s2.62 30.21 11.07 28.85c8.44-1.38 21.67-17.76 21.67-17.76s8.39 18.81 16.93 14.03c8.53-4.8 10.26-88.97-2.77-91.66-13.04-2.7-30.95 10.53-30.95 10.53s-8.55-14.78-20.11-15.57c-11.56-.79-18.17 13.53-18.17 13.53s-22.52-22.59-29.61-18.05c-7.09 4.53-23.97 51.45-6.98 72.19"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3337.97 1360.4s-.26-.58-.76-1.68c-1.78-3.84-6.56-13.66-12.85-23.28-6.23-9.64-14.26-18.96-21.2-21.84l-3.46-1.49 1.51-3.46.38-.9c1.76-4.25 8.92-23.13 8.89-41.1 0-5.92-.77-11.7-2.63-16.82l7.05-2.55c2.25 6.19 3.07 12.82 3.08 19.37-.05 22.67-9.83 44.77-9.9 45.01l-3.43-1.5 1.48-3.45c5.59 2.43 10.55 6.87 15.1 12.05 13.57 15.54 23.52 38.47 23.61 38.63l-6.87 3.01"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3323.43 1324.14l1.2-.11c2.8-.29 10.06-1.33 16.21-3.98 3.07-1.31 5.83-3.02 7.66-5.01 1.83-2.01 2.82-4.1 2.85-6.99l-.06-1.19 7.46-.73.1 1.92c.03 5.59-2.62 10.2-6.13 13.36-5.3 4.79-12.42 7.13-18.25 8.53-5.83 1.37-10.42 1.67-10.55 1.68l-.49-7.48"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3356.42 1301.1l.44 1.09c1.03 2.53 3.82 9.01 7.43 15.02 1.79 3.01 3.8 5.89 5.78 8.06 1.98 2.19 3.96 3.56 5.27 3.9l.57.09 1.41-.52c1.1-.71 2.59-2.51 3.89-4.89 1.97-3.56 3.61-8.25 4.69-11.98.55-1.87.96-3.5 1.24-4.66.27-1.16.4-1.81.4-1.81l7.36 1.48c-.03.12-1.4 6.96-4.12 14.04-1.38 3.55-3.07 7.16-5.31 10.15-1.13 1.49-2.4 2.84-3.97 3.9-1.56 1.05-3.49 1.79-5.59 1.79l-2.64-.38c-3.96-1.19-7.01-4-9.78-7.25-8.17-9.78-13.97-25.17-14.07-25.34l7-2.69"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3392.93 1311.8l.34.82c.81 1.94 3.1 6.99 6.27 11.48 3.15 4.57 7.25 8.13 10.4 8.36l.75.04c1.71-.01 3.29-.65 5.02-2.07 2.55-2.09 5-5.94 6.75-10.04 1.77-4.1 2.9-8.46 3.28-11.36l7.43.98c-.67 4.97-2.68 11.91-6.15 18.05-1.74 3.06-3.85 5.92-6.52 8.14-2.65 2.22-6.01 3.81-9.81 3.8l-1.61-.09c-4.04-.48-7.4-2.59-10.18-5.12-8.24-7.66-12.82-20.08-12.97-20.3l7-2.69"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3912.44 3166.98c0-.02-.78-4.68-2.37-13.12-5.54-29.54-20.78-104.95-45.58-184.53-24.75-79.55-59.27-163.33-102.46-209.1l5.47-5.14c51.11 54.41 88.83 156.84 114.24 245.92 25.35 89.04 38.08 164.63 38.1 164.72l-7.4 1.25"
          ></path>
          <path
            fill="#9b9fd8"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3666.65 3177.18s2.14-158.45 120.94-306.98c0 0 62.1 188.14 72.21 302.62l-96.43-5.56-96.72 9.92"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3859.8 3172.82l-16.99 348.32-175.19-19.1-.97-324.86s14.3-65.36 96.57-65.36c82.29 0 96.58 61 96.58 61"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3666.85 3242.57l.18 63.36s77.7 1.55 138.34 87.74c0 0-22.26-137.04-138.52-151.1"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3462.59 3519.41c.62-14.2 2.15-28.27 4.73-42.19 4.32-23.3 11.51-46.03 21.59-66.82 26.37-54.79 72.37-96.61 136.95-104.47 70.93-8.67 139.89 31.48 179.13 91.69 71.11 108.86 89.67 316.26-36.99 390.11-14.12 8.29-28.73 13.87-43.65 17.18-70.26 15.46-144.94-21.46-193.2-77.51-48.26-56.05-71.49-133.03-68.56-207.99"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3661.08 3569.31c-10.73 7.65-23.83 11.67-36.94 11.68-5.05 0-10.09-.6-15.01-1.83a3.753 3.753 0 011.82-7.28 54.19 54.19 0 0013.19 1.61c11.57 0 23.21-3.59 32.59-10.29a3.75 3.75 0 114.35 6.11"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3564.57 3569.31c-10.74 7.65-23.83 11.67-36.94 11.68-5.05 0-10.1-.6-15.01-1.83a3.745 3.745 0 01-2.73-4.55c.5-2.01 2.53-3.23 4.54-2.73a54.33 54.33 0 0013.2 1.61c11.57 0 23.2-3.59 32.59-10.29a3.751 3.751 0 014.35 6.11"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3614.52 3529.73c-.22-8.29 6.32-15.19 14.6-15.41 8.29-.23 15.2 6.31 15.42 14.6.23 8.28-6.32 15.19-14.6 15.41-8.29.23-15.19-6.31-15.42-14.6"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3523.25 3532.21c-.22-8.29 6.32-15.19 14.61-15.42 8.28-.22 15.19 6.32 15.41 14.6.23 8.29-6.32 15.19-14.6 15.41-8.29.23-15.19-6.31-15.42-14.59"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3572.75 3471.42c-2.24-7.61-9.84-12.58-16.68-15.64-3.47-1.67-7.09-2.93-10.3-4.35-1.54-.73-3.16-1.51-3.89-2.17l-.22-.16.17-1.18c.39-1.79 2.77-3.97 5.71-5.46 2.89-1.67 6.28-2.86 9.68-4.05 6.89-2.2 14.1-4.2 21.46-6.19-7.59-1-15.28-1.02-22.93.39-3.84.67-7.64 1.73-11.45 3.43-3.54 1.86-8.06 4.32-9.68 9.99-.3 1.48-.46 2.5-.2 4.39.41 2.14 1.75 3.56 2.8 4.41 2.17 1.73 4.09 2.36 5.99 3.06 3.77 1.28 7.33 2.21 10.85 3.21 6.9 2.14 13.92 4.26 18.69 10.32"
          ></path>
          <path
            fill="#f25376"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3639.89 3454.72c-2.67-20.32 19.04-39.93 48.49-43.8 29.44-3.87 55.49 9.45 58.15 29.77 2.68 20.32-19.03 39.93-48.48 43.8-29.45 3.87-55.49-9.46-58.16-29.77"
          ></path>
          <path
            fill="#f25376"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3467.32 3477.22c4.32-23.3 11.51-46.03 21.59-66.82 18.23 10.25 28.5 26.92 24.57 42.22-4.23 16.2-23.48 25.75-46.16 24.6"
          ></path>
          <path
            fill="#fff"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3557.9 3386.13s37.9-73.69 87.18 7.99l-87.18-7.99"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3814.34 3433.43c22.29-13.48 55.34-13.11 77.37 1.16 22.02 14.27 36.28 41.75 36.11 69.56-.15 24.95-12.69 50.33-33.56 60.54-20.86 10.21-51.85 1.76-62.11-20.62l-17.81-110.64"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3850.99 3466.03c8.68 3.97 16.37 9.26 22.42 15.82 5.98 6.53 10.19 14.62 10.58 22.9.58 8.27-3.04 16.44-9.51 22.23-6.49 5.79-15.16 9.56-24.5 11.46 9.45 1.01 19.78-.87 28.15-6.89 8.53-5.77 14.04-16.5 13.34-27.2-.67-10.7-6.39-20.25-14-26.68-7.54-6.65-17.02-10.47-26.48-11.64"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3460.85 3782.99s13.65-94.83 77.69-136.93c64.06-42.09 176.9-46.71 196.26-40.93 19.37 5.78-26.27 69.04-26.27 69.04s97.37-52.22 137.6-23.18c40.23 29.05-5.67 270.11-385.28 132"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M3831.86 3740.71s-56.18-124.13-45.31-158.26c9.38-29.47 73.25-15.68 73.25-15.68s67.63 132.56-27.94 173.94"
          ></path>
          <g>
            <g
              fillOpacity="1"
              fillRule="nonzero"
              stroke="none"
              clipPath="url(#clipPath240)"
            >
              <path
                fill="#6467ba"
                d="M920.234 1978.15s-293.57-150.25-447.156-918.03H1636.16l116.34 918.03H920.234"
              ></path>
              <path
                fill="#151312"
                d="M844.965 1913.62h899.836v7.5H844.965z"
              ></path>
              <path
                fill="#8787cb"
                d="M1215.24 1062.41h-10.09l50.74 378.27 9.92-1.33-50.57-376.94"
              ></path>
              <path
                fill="#f4f7fc"
                d="M964.934 1978.15h-6.114c1.336 1.92 2.039 2.9 2.047 2.92l4.067-2.92"
              ></path>
              <path
                fill="#8787cb"
                d="M629.504 1185.2c-3.082 1.66-6.129 3.31-9.195 5 63.828 322.69 219.433 600.5 296.218 723.42h11.793a2310.48 2310.48 0 01-7.718-12.37c-29.422-47.53-69.227-116.04-110.719-199.89-68.621-138.65-141.828-319.24-180.379-516.16m303.555 735.92h-11.825c19.121 30.32 32.657 49.98 37.586 57.03h6.114l4.062-2.91c-.004 0-1.168-1.62-3.402-4.84a1114.49 1114.49 0 01-9.742-14.3c-5.911-8.82-13.633-20.56-22.793-34.98"
              ></path>
              <path
                fill="#373436"
                d="M928.32 1913.62h-11.793c1.606 2.57 3.172 5.07 4.707 7.5h11.825c-1.539-2.42-3.118-4.92-4.739-7.5"
              ></path>
              <path
                fill="#151312"
                d="M1364.86 1563.97s-108.88-299.92-252.07-503.85h-57.88l89.2 371.89s158.43 59.13 220.75 131.96"
              ></path>
              <path
                fill="#151312"
                d="M1499.37 1978.76c0-.01-.58-3.58-1.7-10.02-3.9-22.53-14.26-80.02-28.48-140.55-14.18-60.49-32.35-124.19-51.36-158.43l-4.26-7.7 8.51 2.26c.46.12 39.33 10.55 83.05 54.99 43.73 44.43 92.15 122.84 111.93 258.3l-7.43 1.09c-19.61-134.16-67.3-210.86-109.85-254.13-21.28-21.63-41.29-34.91-55.94-42.77-7.33-3.93-13.31-6.5-17.43-8.09-2.06-.79-3.65-1.34-4.72-1.68l-1.19-.37-.35-.1.96-3.62 3.28-1.82c22.57 40.83 42.96 118.39 57.97 186.11 14.98 67.67 24.4 125.28 24.41 125.32l-7.4 1.21"
              ></path>
            </g>
          </g>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2554.79 2521.81s83.93-153.38-46.66-250.04c0 0-77.32 133.35 46.66 250.04"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2881.35 2450.67l36.88 5.59s29.06-51.43 43.61-51.43c14.53 0 17.88 51.43 8.93 65.96-8.93 14.53-52.54 57.01-70.42 50.31-17.89-6.71-215.61-65.75-215.61-65.75l15.65-145.71c21.81-19.92 57.91-14.8 57.91-14.8s-3.42 18.87 13.55 38.31c19.64 22.53 109.5 117.52 109.5 117.52"
          ></path>
          <path
            fill="#9b9fd8"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1548.19 3183.48s228.51-5.67 252.5-29.66c24-23.99-139.13-647.65-139.13-719.8 0-36.29 112.09-247.93 141.61-295.9 29.52-47.99-50.67-159.97-50.67-159.97H761.461s221.469 968.73 278.449 1049.71c56.98 80.97 222.21 135.13 315.12 151.27l193.16 4.35"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1548 3219.39s52.75-5.48 65.34-22.34c12.6-16.86-13.47-183.7-57.41-230.87l-86.13 62.06-84.4-82.06s-98.38 111.85-93.05 184.57c5.04 68.64 66.08 111.82 66.08 111.82l189.57-23.18"
          ></path>
          <path
            fill="#f2577d"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M506.852 2940.47L796.73 1869.32s562.31 44.33 706.34 261.43L1126.04 3174.3s-498.579-223.77-619.188-233.83"
          ></path>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M626.664 2883.47L968.961 1827.9s559.439 71.97 692.599 295.91l-427.98 1023.71s-486.947-248.06-606.916-264.05"
          ></path>
          <path
            fill="#f9c478"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1521.63 3879.31s55.24 61.6 130.82 61.6c56.75 0 164.43-140.66 179.23-250.64 18-133.67-56.98-296.6-329.9-224.24-350.32 92.87-110.97 351.68 19.85 413.28"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1752.25 3519.41c-.62-14.2-2.15-28.27-4.74-42.19-4.31-23.3-11.5-46.03-21.58-66.82-26.38-54.79-72.37-96.61-136.96-104.47-70.92-8.67-139.88 31.48-179.12 91.69-71.11 108.86-89.67 316.26 36.99 390.11 14.12 8.29 28.73 13.87 43.65 17.18 70.25 15.46 144.93-21.46 193.19-77.51 48.27-56.05 71.5-133.03 68.57-207.99"
          ></path>
          <path
            fill="#f9c478"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1558.11 3563.2c9.39 6.7 21.02 10.29 32.59 10.29 4.45 0 8.89-.53 13.19-1.61a3.753 3.753 0 011.82 7.28 61.921 61.921 0 01-15.01 1.83c-13.11-.01-26.21-4.03-36.94-11.68a3.75 3.75 0 01-.88-5.23 3.748 3.748 0 015.23-.88"
          ></path>
          <path
            fill="#f9c478"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1654.62 3563.2c9.39 6.7 21.01 10.29 32.59 10.29 4.45 0 8.89-.53 13.19-1.61 2.01-.5 4.04.72 4.55 2.73.5 2.01-.72 4.05-2.73 4.55a61.921 61.921 0 01-15.01 1.83c-13.11-.01-26.21-4.03-36.94-11.68a3.751 3.751 0 014.35-6.11"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1372.03 3521.14l-17-348.32c-3.93-64.31 32.54-118.79 83.23-124.31 58.54-6.39 110.57 54.51 109.93 128.67l-.97 324.86-175.19 19.1"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1548 3242.57l-.19 63.36s-77.69 1.55-138.35 87.74c0 0 22.28-137.04 138.54-151.1"
          ></path>
          <path
            fill="#f9c478"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1421.11 3965.78c-63.59-3.15-308.32-221.76-308.32-388.54 0-134.13 194.86-286.28 149.31-329.72-45.54-43.43-207.19-183.98-207.19-285.95s288.13-83.97 291.02-155.95c2.89-71.98-58.91-101.97-58.91-101.97s183.12 29.99 164.45 149.95c-18.67 119.97-122.3 83.98-124.47 137.96-2.17 53.99 249.14 148.78 40.27 432.03l15.43 120.48s141.29 17.33 197.67 147.8c56.37 130.45-68.59 278.41-159.26 273.91"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1600.31 3529.73c.23-8.29-6.31-15.19-14.6-15.41-8.28-.23-15.18 6.31-15.41 14.6-.22 8.28 6.31 15.19 14.6 15.41 8.29.23 15.19-6.31 15.41-14.6"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1691.58 3532.21c.23-8.29-6.31-15.19-14.6-15.42-8.28-.22-15.19 6.32-15.41 14.6-.23 8.29 6.31 15.19 14.6 15.41 8.28.23 15.19-6.31 15.41-14.59"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1642.08 3471.42c4.78-6.06 11.8-8.18 18.69-10.32 3.53-1 7.08-1.93 10.85-3.21 1.91-.7 3.82-1.33 6.01-3.06 1.04-.85 2.37-2.27 2.79-4.41.25-1.89.11-2.91-.21-4.39-1.62-5.67-6.13-8.13-9.66-9.99-3.82-1.7-7.62-2.76-11.46-3.43-7.65-1.41-15.35-1.39-22.93-.39 7.36 1.99 14.57 3.99 21.46 6.19 3.41 1.19 6.79 2.38 9.67 4.05 2.95 1.49 5.33 3.67 5.72 5.46l.17 1.18-.22.16c-.73.66-2.35 1.44-3.89 2.17-3.21 1.42-6.83 2.68-10.31 4.35-6.84 3.06-14.43 8.03-16.68 15.64"
          ></path>
          <path
            fill="#f25376"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1574.95 3454.72c2.67-20.32-19.04-39.93-48.49-43.8-29.45-3.87-55.49 9.45-58.16 29.77-2.67 20.32 19.03 39.93 48.49 43.8 29.45 3.87 55.49-9.46 58.16-29.77"
          ></path>
          <path
            fill="#f25376"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1747.51 3477.22c-4.31-23.3-11.5-46.03-21.58-66.82-18.22 10.25-28.5 26.92-24.57 42.22 4.23 16.2 23.48 25.75 46.15 24.6"
          ></path>
          <path
            fill="#fff"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1656.93 3386.13s-37.9-73.69-87.17 7.99l87.17-7.99"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1400.5 3433.43c-22.29-13.48-55.34-13.11-77.37 1.16-22.02 14.27-36.28 41.75-36.11 69.56.16 24.95 12.69 50.33 33.56 60.54 20.87 10.21 51.85 1.76 62.12-20.62l17.8-110.64"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1363.85 3466.03c-9.47 1.17-18.94 4.99-26.48 11.64-7.6 6.43-13.33 15.98-14.01 26.68-.69 10.7 4.81 21.43 13.34 27.2 8.38 6.02 18.71 7.9 28.16 6.89-9.34-1.9-18.01-5.67-24.5-11.46-6.47-5.79-10.09-13.96-9.52-22.23.39-8.28 4.6-16.37 10.59-22.9 6.04-6.56 13.74-11.85 22.42-15.82"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M868.961 2447.71s181.259-159.47 95.973-251.92c0 0-173.954 83.98-95.973 251.92"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1319.17 2604.77l-474.205-197.04s27.996-143.96 103.973-187.94l448.582 261.14-78.35 123.84"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2684.74 2455.35l-512.47 32.49s-38.43-141.53 10.34-214.54l517.78 36.34-15.65 145.71"
          ></path>
          <path
            fill="#9b9fd8"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M594.316 2617.84s-301.191-322.08-121.242-454.04c82.399-60.43 491.86 31.99 491.86 31.99s-127.965 179.95-95.973 251.92l-218.535-34.58-56.11 204.71"
          ></path>
          <path
            fill="#f4668a"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1319.17 2604.77s33.17 51.42 65.62 80.16c6.8 6.01 114.67 54.24 121.09 45.68 6.41-8.56-4.47-28.69-13.56-35.34 0 0 8.45-17.34 6.16-21.89-2.3-4.54-4.14-19.42-4.14-19.42s13.58-1.2 11.71-10.71c-1.86-9.52-4.07-19.71-4.07-19.71s18.52-10.58 20.54-16.82c6.06-18.73-39.16-102.69-125-125.79l-78.35 123.84"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M649.797 2416.83l-90.379-15.4 1.262-7.39 90.375 15.4"
          ></path>
          <path
            fill="#9b9fd8"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1800.69 3153.82c70.7-3.78 255.93-632.01 255.93-632.01h498.17s-46.66-124.08-46.66-250.04c0 0-493.5 0-565.48-6-71.98-5.99-242.76 348.52-290.2 501.86-36.19 116.95 30.28 392.49 148.24 386.19"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1701.09 2638.99s114.6 70.82 108.6 232.61c0 0 71.98-229.93-34.02-390.67l-74.58 158.06"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1660.38 2414.61c-.01.02-3.54 8.29-8.85 21.08-7.98 19.19-19.94 48.47-29.89 74.9-4.98 13.21-9.45 25.71-12.66 35.83-3.22 10.04-5.12 18.07-5.06 20.99l.05.71c.25.92.5 1.19 1.16 1.62.67.4 1.94.74 3.68.74 5.02.06 13.2-2.89 19.58-7.32 3.21-2.19 6.01-4.74 7.89-7.26 1.9-2.53 2.82-4.94 2.81-6.93l-.17-1.49 7.31-1.67c.24 1.05.36 2.11.36 3.16-.01 4.22-1.79 8.07-4.31 11.44-3.82 5.06-9.47 9.3-15.48 12.43-6.04 3.1-12.36 5.11-17.99 5.14-2.62-.01-5.16-.45-7.43-1.75-2.28-1.27-4.1-3.61-4.71-6.37l-.25-2.45c.02-3.42.99-7.88 2.57-13.71 5.54-20.22 19.13-55.58 31.37-86.07 12.24-30.47 23.12-55.95 23.12-55.97l6.9 2.95"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2053.44 2523.8l-46.47-74.09 6.35-3.99 46.48 74.09"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M781.344 2434.75c-.004-.08-.91-11.21-.91-29.47.05-51.12 6.957-158.13 61.472-235.28l6.121 4.33c-52.922 74.62-60.148 180.52-60.097 230.95 0 9 .226 16.24.449 21.21.223 4.98.441 7.64.441 7.65l-7.472.61"
          ></path>
          <path
            fill="#151312"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M2450.14 2523.04c-.05-.21-31.59-90.25-31.62-182.37 0-23.04 1.98-46.23 6.96-68.2l7.32 1.66c-4.83 21.28-6.78 43.93-6.78 66.54 0 45.22 7.79 90.27 15.61 123.96 3.9 16.84 7.81 30.85 10.74 40.64 1.46 4.89 2.68 8.73 3.53 11.34.85 2.6 1.32 3.95 1.32 3.96l-7.08 2.47"
          ></path>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4017.35 3432.22l-3.55-1.2 99.68-293.49h528.6l-101.37 298.44h-528.59l1.68-4.95 3.55 1.2v-3.75h517.99l96.27-283.44h-512.75l-97.96 288.4-3.55-1.21v-3.75 3.75"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4123.26 3491.99l98.82-290.95h520.67l-98.81 290.95h-520.68"
          ></path>
          <path
            fill="#c8c9ea"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4577.66 3077.2l165.09 123.84h-165.09z"
          ></path>
          <path
            fill="#fff"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4669.12 3310.26h-98.9v23.54h98.9v-23.54"
          ></path>
          <path
            fill="#fff"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M4669.12 3252.17h-174.06v23.54h174.06v-23.54"
          ></path>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M1047.54 3797.12h-3.75c0-82.95-33.61-158.02-87.97-212.38-54.363-54.36-129.429-87.97-212.382-87.97-82.954 0-158.016 33.61-212.379 87.97-54.36 54.36-87.969 129.43-87.973 212.38.004 82.95 33.613 158.01 87.973 212.38 54.363 54.36 129.425 87.97 212.379 87.97 82.953 0 158.019-33.61 212.382-87.97 54.36-54.37 87.97-129.43 87.97-212.38h7.5c0 170.02-137.825 307.85-307.852 307.85-170.02 0-307.848-137.83-307.852-307.85.004-170.03 137.832-307.85 307.852-307.85 170.027 0 307.852 137.82 307.852 307.85h-3.75"
          ></path>
          <path
            fill="#e0e4f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M941.344 3853.65c0-167.95-136.149-304.1-304.102-304.1-167.949 0-304.101 136.15-304.101 304.1 0 167.95 136.152 304.1 304.101 304.1 167.953 0 304.102-136.15 304.102-304.1"
          ></path>
          <path
            fill="#e0e4f5"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M742.07 3599.24l214.993-102.49-93.54 220.51z"
          ></path>
          <path
            fill="#f25376"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M637.242 3695.14c-87.531 0-158.512 70.92-158.512 158.51 0 87.53 70.981 158.51 158.512 158.51 60.246 0 112.66-33.66 139.5-83.11 12.129-22.41 19.012-48.12 19.012-75.4 0-87.59-70.984-158.51-158.512-158.51"
          ></path>
          <path
            fill="#fff"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M637.242 3853.65v158.51c60.246 0 112.66-33.66 139.5-83.11l-139.5-75.4"
          ></path>
          <path
            fill="#342b6f"
            fillOpacity="1"
            fillRule="nonzero"
            stroke="none"
            d="M795.754 3853.65H637.242l139.5 75.4c12.129-22.41 19.012-48.12 19.012-75.4"
          ></path>
        </g>
      </g>
    </svg>
  );
}