import { Url } from '../../enums/route';
import { ISolution } from '../../interfaces/ISolution';
import enforcement from '../../extras/images/enforcement.jpg'
import iotimg from '../../extras/images/iotimg.jpg';
import iot from '../../extras/images/iot.jpg';
import data_vis from '../../extras/images/data-vis.jpg';
// import ai from '../../extras/images/ai.jpg'
// import db from '../../extras/images/db.jpg' 

export const solutionsArray: Array<ISolution> = [
    {
        id: 'iot',
        alt: 'Autonomous (IoT)',
        img: iot,
        mainTitle: 'Autonomous (IoT) Road Use Penalty/Enforcement System',
        content: 'Automatic plate number detection, red light violation detection, overspeed detection, parking lot optimmization vehicle classification and many other safety measure systems.',
        moreDetails: 'At the core of our enforcement and penalty system is eTraffika License Plate Recognition. This is an effective process of detecting and recognizing vehicle number plates using the concept of Optical Character Recognition (OCR) on image. License plate number detection and recognition is a three-stage process.',
        detailList: [
            'License Plate Detection – Considered as the most important step of the process. Vehicle image is captured by smart cameras and transmitted to eTraffika cloud for processing.',
            'Character Segmentation – This is the 2nd stage where alphanumeric characters from the license plate are extracted and segmented into individual images.',
            'Character recognition – Each individual character segmented in stage two gets recognized using machine learning technology.'
        ],
        linkTo: `${Url.solutions}/iot`,
    },
    {
        id: 'manned-enforcement',
        alt: 'Manned Enforcement System',
        img: enforcement,
        mainTitle: 'Manned Enforcement System',
        content: 'The eTraffika Field Officer Solution is an end to end enforcement solution that provides handheld devices to field officers. The handheld devices are connected to a cloud backend real-time.',
        moreDetails: 'Field Officers are equipped with handheld devices which are connected to our robust backend cloud based services. This allows for real-time information exchange between which enables the Field Officers instantly retrieve information about a vehicle or driver from any state of the Federation.',
        detailList: [
            'Handheld devices are connected to eTraffika backend systems which provides the officers with real time history data',
            'Officers can book offenders and process payment on the spot.',
            'Has the capacity to capture location details for videos and photos taken as evidences and these are pushed to eTraffika repository.'
        ],
        linkTo: `${Url.solutions}/manned-enforcement`,
    },
    {
        id: 'data',
        alt: 'Data visualization & analysis',
        img: data_vis,
        mainTitle: 'Data visualization & analysis',
        content: 'Data visualization is the graphical representation of information and data. By using visual elements like charts, graphs, and maps, data visualization tools provide an accessible way to see and understand trends, outliers, and patterns in data.',
        moreDetails: '...',
        linkTo: `${Url.solutions}/data`,
    },
    {
        id: 'AI',
        alt: 'Artificial Intelligence',
        img: iotimg,
        mainTitle: 'Artificial Intelligence and deep learning powered detection technology',
        content: 'At the heart of our solution is our cloud system that connects autonomous and manned IoT devises. The cloud computer infrastructure provides the data and machine learning computing required to support our solutions.',
        moreDetails: '...',
        linkTo: `${Url.solutions}/AI`,
    },
]