import React from "react";

export function VisionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4000"
      height="2666.667"
      version="1.1"
      viewBox="0 0 4000 2666.667"
      xmlSpace="preserve"
    >
      <g transform="matrix(1.33333 0 0 -1.33333 0 2666.667)">
        <g
          fillOpacity="1"
          fillRule="nonzero"
          stroke="none"
          transform="scale(.1)"
        >
          <path fill="#fff" d="M30000 0H0v20000h30000V0"></path>
          <path
            fill="#ebf3fa"
            d="M4715.09 2812.1s-3615.03 3841.8-1080.15 7425.5c945.88 1337.3 3360.68 2088.2 5580.48 1479 1311.18-359.8 3299.68-306.7 5242.68 2865.1 2649.6 4325.4 13649 2358.6 11502-5219.8-904.4-3192.2 2466.4-3337.5 796.7-6556.7l-22041.71 6.9"
          ></path>
          <path
            fill="#d5e5fb"
            d="M24286.1 10508.3l-7874.2-6587.5L12269 7450.1c-775.5-682.8-1973-1736.8-3177.78-2794.4-823.32-722.8-1489.89-1306.8-2030.18-1779.5l-3762.94 4.4c1043.5 888.4 5245.04 4580.5 8075.6 7075.7l865.5 762.9 4188-3567.8 6147.1 5142.7 1711.8-1785.8"
          ></path>
          <path
            fill="#d5e5fb"
            d="M21577.5 13104.3l3750.2-3761.7 1059.4 4297.2z"
          ></path>
          <path
            fill="#b7d3f3"
            d="M9283.61 5178.2c-14.01 344.4-44.2 644.1-80.33 853-57.61 333.1-109.46 524.7-110.06 526.7-7.59 29.3-117.2 147.9-360.92 144.2-243.71-3.6-220.6-159.1-220.6-159.1-.02-5.2.67-10.1 1.75-13.8 2.35-8 226.06-819.2 216.37-1866.5-.13-14.7-1.78-40.1-.74-57 .01-.1 564.78-14.6 564.91 0 19.7 91.5 10.09 345.1-10.38 572.5"
          ></path>
          <path
            fill="#d2e3fc"
            d="M9354.62 4667.9c-.17-14.6-7.87-26.3-17.27-26.3h-.22c-9.39.2-16.87 11.9-16.98 26.3h-484.01c-.17-14.6-7.88-26.3-17.27-26.3h-.23c-9.56.2-17.15 12.3-17.04 27 2.81 299.8-14.28 579.8-40.15 826.9-64.35 616.2-183.29 1028-184.98 1033.7-1.07 3.7-1.74 8.6-1.74 13.8 0 0-23.12 155.4 220.57 159.1 243.75 3.7 353.37-114.9 360.96-144.2.45-1.5 29.81-110 68.84-305.1 12.93-64.6 26.88-138.7 41.22-221.6 36.11-208.9 66.31-508.6 80.32-853 20.46-227.4 27.67-418.8 7.98-510.3"
          ></path>
          <path
            fill="#c5dcf8"
            d="M8765.83 4641.6h1897.57s-44.2 178.8-1143.14 152.2c-777.7-18.9-754.43-152.2-754.43-152.2"
          ></path>
          <path
            fill="#b7d3f3"
            d="M8611.38 2773.7c220.02 1113.8 163.44 1797.3 163.17 1805.9-.56 17.2 31.05 32.7 48.05 33.3 17.21.3 93.15 5.1 101.94-9.6 11.51-19.2 55.82-861.3-272.09-1829.6h-41.07"
          ></path>
          <path
            fill="#b7d3f3"
            d="M10383.1 2773.7c-220 1113.8-163.4 1797.3-163.1 1805.9.5 17.2-31.1 32.7-48.1 33.3-17.2.3-93.1 5.1-101.9-9.6-11.5-19.2-55.9-861.3 272.1-1829.6h41"
          ></path>
          <path
            fill="#b7d3f3"
            d="M10734.2 2773.7c-220 1113.8-163.4 1797.3-163.1 1805.9.5 17.2-31.1 32.7-48.1 33.3-17.2.3-93.1 5.1-101.9-9.6-11.5-19.2-55.8-861.3 272.1-1829.6h41"
          ></path>
          <path
            fill="#b7d3f3"
            d="M8964.86 2773.7c220.02 1113.8 163.45 1797.3 163.17 1805.9-.55 17.2 31.05 32.7 48.05 33.3 17.22.3 93.16 5.1 101.94-9.6 11.52-19.2 55.82-861.3-272.09-1829.6h-41.07"
          ></path>
          <path
            fill="#b7d3f3"
            d="M10664.5 4584.5H8766.96c-21.38 0-38.87 17.5-38.87 38.9v25.5c0 21.4 17.49 38.9 38.87 38.9h1897.54c21.4 0 38.9-17.5 38.9-38.9v-25.5c0-21.4-17.5-38.9-38.9-38.9"
          ></path>
          <path
            fill="#ffb27d"
            d="M10597.9 6298.2l630.7 136.6 37.2-90.5s-696-414.2-761.2-393.9c-65.2 20.3 93.3 347.8 93.3 347.8"
          ></path>
          <path
            fill="#ffb27d"
            d="M10024.4 3445.4c-15.8-18.1-17.8-38.3-10-59.9 0-.1.1-.2.2-.3 14.4-39.9 62.2-84.6 117.7-129.1 7.1-5.7 13.4-11 18.9-15.8 1.4-1.2 2.9-2.5 4.2-3.8 1.1-1 2.2-2 3.3-2.9 51.7-47.7 32.3-58.6 125.2-155.4 106-110.3 179-112.3 199.7-102.3 17.6 8.6-18.1 67.1-57.6 132.8-1.9 3.2-3.8 6.4-5.8 9.6-5.2 8.8-10.5 17.6-15.7 26.4-46.3 79.1-34.4 116.3-23.5 234 9 95.7 148.9 534.6 148.9 534.6l-203.1 35s-61.2-349.5-128.6-403.3c-36-28.7-76.4-29.1-111-50.3-2.5-1.5-4.9-3-7.2-4.6-19.7-13-39.7-26.5-55.6-44.7"
          ></path>
          <path
            fill="#1c468a"
            d="M10024.4 3445.4c-15.8-18.1-17.8-38.3-10-59.9 0-.1.1-.2.2-.3 17.7-39.9 64.2-84.3 117.9-128.7 7-5.8 13.2-11.2 18.7-16.2 1.4-1.2 2.9-2.5 4.2-3.8 1.1-1 2.2-2 3.3-2.9 51.7-47.7 32.3-58.6 125.2-155.4 106-110.3 179-112.3 199.7-102.3 17.6 8.6-18.1 67.1-57.6 132.8-20.6 7.6-116.5 46.5-189 139.3-63.8 81.4-106.3 186.1-149.8 246.7-2.5-1.5-4.9-3-7.2-4.6-19.7-13-39.7-26.5-55.6-44.7"
          ></path>
          <path
            fill="#ffb27d"
            d="M11662.3 3572.3c12.5-62.7 101.9-52.2 201.8-27.8 99.8 24.4 76.1-4.6 218.2 11.1s185.2 68.7 189.5 89.8c3.9 18.8-66.3 25.4-141.7 34.1-9.4 1-19 2.2-28.5 3.4-85 10.7-147.3 65.5-224.5 110.6-73.2 42.7-182.4 216.6-182.4 216.6l-194.9-15.4s102.3-155.4 141.9-222.9c23.2-39.7 22-92.3 19.1-132.8-1.5-22.1-2.9-44.6 1.5-66.7"
          ></path>
          <path
            fill="#1c468a"
            d="M11864.1 3544.5c99.8 24.4 76.1-4.6 218.2 11.1s185.2 68.7 189.5 89.8c3.9 18.8-66.3 25.4-141.7 34.1l-.7-1.5s-85.2-60.4-203.5-63.2c-96.4-2.4-198.5 25.3-265.1 24.2-1.5-22.1-2.9-44.6 1.5-66.7 12.5-62.7 101.9-52.2 201.8-27.8"
          ></path>
          <path
            fill="#ffb27d"
            d="M11228.6 6434.8s46.5 113.8 97.6 118.4c51 4.7 213.6 30.2 225.2 7 11.6-23.2 0-106.8-34.9-137-34.8-30.2-250.7-78.9-250.7-78.9l-37.2 90.5"
          ></path>
          <path
            fill="#163560"
            d="M10915.4 4818.5l-420-1130.4s-64.5-6.2-126.4 11.6c-56.9 16.4-122.2 59.5-122.2 59.5s104.2 1085.4 295.3 1311.1c191 225.8 373.3-251.8 373.3-251.8"
          ></path>
          <path
            fill="#1c468a"
            d="M9254.09 5275.8s1206.91 408.1 1476.11 321.3c269.2-86.9 1077.3-1694.8 1077.3-1694.8s-18.2-24.2-68-42.9c-46.3-17.4-158.4-16.3-158.4-16.3s-729.4 625.2-816.2 807.5c-86.8 182.4-121.5 356-208.4 338.7-86.8-17.4-1120.07-425.5-1354.51-182.4-234.44 243.1-173.13 559-173.13 559l225.23-90.1"
          ></path>
          <path
            fill="#b5453c"
            d="M9942.17 8153.5c-52.17 29.6-114.69 32.9-158.42 30.3 11.26 11.6 33.41 15.9 33.41 15.9-27.12 4-87.64-22.9-87.64-22.9-377.2 145.3-436.49-410.1-428.56-539.1 7.86-129 8.82-277.5-179.48-393.3-370.49-227.7-57.89-625.2-57.89-625.2 2.92-1.7 172.38-289.1 172.38-289.1-9.51 104.3 471.59 654.5 722.59 819.3 61.14 90 82.84 248.9 84.44 261.4-10.5-126-32.1-196.7-51.36-236.5 212.56 195.6 88.76 900.6-49.47 979.2"
          ></path>
          <path
            fill="#ffb27d"
            d="M9943.71 7141.2c1.25-.5-121.2-64.1-213.3-38.9-86.64 23.6-143.25 135.9-142.06 136.2 36.7 7.3 47.28 57.7 46.5 274.3l16.59-3.2 278.88-55.2s-6.34-87.8-6.53-170.5c-.19-69.4 4.02-135.3 19.92-142.7"
          ></path>
          <path
            fill="#ed975d"
            d="M9930.32 7454.4s-6.34-87.8-6.53-170.5c-156.06 4.4-238.55 146.8-272.35 225.7l278.88-55.2"
          ></path>
          <path
            fill="#b5453c"
            d="M9622.63 7539.9s-81.79 129.3-101.41 239.8c-14.02 78.8 26.16 371.3 320.01 329.1 0 0 85.34-8.4 165.57-62.6 63.8-43 155.9-136.1 52.9-391.2l-26.7-86.2-410.37-28.9"
          ></path>
          <path
            fill="#ffb27d"
            d="M9982.44 7376.1s-369.24-6.1-369.5 223.9c-.25 230-48.08 384.1 187.67 397.7 235.69 13.6 281.59-74.1 300.39-150.8 18.8-76.8-22.5-463.3-118.56-470.8"
          ></path>
          <path
            fill="#b5453c"
            d="M9968.77 8003.3s-101.09-269.8-256.25-309.7c-155.15-39.9-222.45 1.7-222.45 1.7s119.01 94.5 143.71 259.5c0 0 258.73 177 334.99 48.5"
          ></path>
          <path
            fill="#b5453c"
            d="M9943.72 7981.4s75.48-60.2 93.88-118.8c18.4-58.6 26.7-179.1 61.9-175 0 0 76.1 183.4-9.5 287.1-90.74 110.1-146.28 6.7-146.28 6.7"
          ></path>
          <path
            fill="#1c468a"
            d="M9121.48 6147.9c-36.41 152.7 87.48 449 216.8 694.3 117.34 222.4 239.14 402.8 250.08 396.3 309.47-183.7 337.44-31 337.44-31h.01c.12 0 281.09-37.3 360.69-174.3 98.7-170 205.4-645.1 311.4-735-16.3-49.8-9.3-76.3 7.9-138 3.7-13.2 7.8-25.8 12.3-37.7 16-43.4 35.9-78.7 53.2-109.2-53.9-32.8-157.9-95-234.5-50.3-116.8 68.4-208 276.5-259.3 359.4-.2.3-.3.6-.5.9-111.5-631.1 40.1-774.8-36.7-841.3-81-70.3-196.79 33.4-451.51-77-167.06-72.5-398.91-177.2-554.98-195.5-81.85-9.6-142.93 4.7-162.84 59.9-57.89 160.7 219.98 587.3 150.51 878.5"
          ></path>
          <path
            fill="#fff"
            d="M9900.36 7515.5s6.88-29.2 79.57-24.7c0 0 61.07 3.7 59.17 35.9 0 0-59.19 22.8-138.74-11.2"
          ></path>
          <path
            fill="#163560"
            d="M8970.97 5269.4c19.91-55.2 80.99-69.5 162.84-59.9 108.03 47.1 574.67 227.9 569.46 350.4-7.76 181.3-89.73 187.1-31.84 411 46.49 179.7-384.69 513.9-453.47 586.6-129.32-245.3-132.89-256.9-96.48-409.6 69.47-291.2-208.4-717.8-150.51-878.5"
          ></path>
          <path
            fill="#1c468a"
            d="M8918.22 5923.6c82.76 116.2 168.97 177.6 225.68 208.5-13.05 33.7 348.09 476.4 348.09 545 0 69.5 96.36 561.4 96.36 561.4s-300.91-18.6-405.1-163.9c-130.3-181.7-400.91-881-265.03-1151"
          ></path>
          <path
            fill="#ffb27d"
            d="M9143.9 6132.1l601.34-156.9-56.45-101.3s-731.79 5.1-770.57 49.7c-38.78 44.5 225.68 208.5 225.68 208.5"
          ></path>
          <path
            fill="#163560"
            d="M10177.5 6322.4c51.3-82.9 142.5-291 259.3-359.4 76.6-44.7 180.6 17.5 234.5 50.3-17.3 30.5-37.2 65.8-53.2 109.2-43-9.9-95.4-22.4-129.3-32.6-65.8-19.9-311.3 232.5-311.3 232.5"
          ></path>
          <path
            fill="#ffb27d"
            d="M9745.24 5975.2s188.83 53.5 233.81 41c44.95-12.5 199.05-114.6 177.45-142.3-21.7-27.7-157.46 16.6-219.93 8.2-62.47-8.4-110.78-39.2-155.76-32.5-44.98 6.6-92.02 24.3-92.02 24.3l56.45 101.3"
          ></path>
          <path
            fill="#ffb27d"
            d="M9745.24 5975.2s92.39 47.5 118.67 74.5c3.31 3.4 8.92 2.7 11.47-1.3 7.04-11.1 13.63-32.6-18.77-53.9-46.65-30.7-111.37-19.3-111.37-19.3"
          ></path>
          <path
            fill="#ed7d2b"
            d="M10617.9 5709.5h-880.99c0 38.8 31.44 70.3 70.23 70.3h810.76v-70.3"
          ></path>
          <path
            fill="#ed7d2b"
            d="M10615.4 6669.1h1362.1l-326.7-959.6h-1371l335.6 959.6"
          ></path>
          <path
            fill="#f99746"
            d="M11997.6 6669l-326.8-959.4h-1370.9l335.5 959.4h1362.2"
          ></path>
          <path
            fill="#f9ddbf"
            d="M11263.2 6198.2c-8.5-36.7-45.1-66.4-81.7-66.4-36.7 0-59.5 29.7-51.1 66.4 8.5 36.6 45.1 66.4 81.7 66.4 36.7 0 59.6-29.8 51.1-66.4"
          ></path>
          <path
            fill="#ed893e"
            d="M11997.6 6669l-326.8-959.4h-907.9c601.4 205.2 761.5 831.1 788.4 959.4h446.3"
          ></path>
          <path
            fill="#95c0f4"
            d="M8043.18 2773.7c17.23 0 31.96 12.1 34.9 28.7l171.08 969.6 246.07 1394.7 68.81 389.9-175.27 5-30.41-226.4-213.51-1590.9-126.59-943c-1.95-14.6 9.74-27.6 24.92-27.6"
          ></path>
          <path
            fill="#78acea"
            d="M8358.36 5335.2c113.56-50.1 136.87-168.5 136.87-168.5l68.81 389.9-175.27 5-30.41-226.4"
          ></path>
          <path
            fill="#95c0f4"
            d="M12402.6 2773.7c-17.2 0-31.9 12.1-34.9 28.7l-171 969.6-246.1 1394.7-68.8 389.9 175.2 5 30.4-226.4 213.6-1590.9 126.5-943c2-14.6-9.7-27.6-24.9-27.6"
          ></path>
          <path
            fill="#78acea"
            d="M12087.4 5335.2c-113.5-50.1-136.8-168.5-136.8-168.5l-68.8 389.9 175.2 5 30.4-226.4"
          ></path>
          <path
            fill="#78acea"
            d="M7926.24 5403.5h4488.36c58.8 0 106.9 48.1 106.9 106.9v92.4c0 58.8-48.1 106.9-106.9 106.9H7926.24c-58.8 0-106.9-48.1-106.9-106.9v-92.4c0-58.8 48.1-106.9 106.9-106.9"
          ></path>
          <path
            fill="#2e3552"
            d="M28077.1 2783H1688.58c-16.48 0-29.96 13.4-29.96 29.9v10.3c0 16.4 13.48 29.9 29.96 29.9H28077.1c16.5 0 30-13.5 30-29.9v-10.3c0-16.5-13.5-29.9-30-29.9"
          ></path>
          <path
            fill="#ffb27d"
            d="M19074.7 5872.9s-116.5-144.4-80-164.1c36.3-19.7 132.5-56.5 155.2-33.3 22.7 23.2 29.8 193.4 29.8 193.4l-105 4"
          ></path>
          <path
            fill="#ffb27d"
            d="M19079.4 5870.5s-47.6-16.5-66.3-41.3c-18.7-24.7-60.1-62.5-50-72.9 10.2-10.5 69.6 35.7 69.6 35.7l46.7 78.5"
          ></path>
          <path
            fill="#aec3ff"
            d="M19514.8 7801.2s-131.2-1221.7-142.9-1256.5c-2.5-7.5-12.8-46.2-27.3-101.8-20.7-79.5-49.9-193.7-77.6-302.3-41.7-164-80-315.4-80-315.4l-114 37.6s-5.9 593.7 23.4 730.4c29.4 136.7 123.1 976.7 170.5 1089.7 47.2 113 247.9 118.3 247.9 118.3"
          ></path>
          <path
            fill="#a02e2e"
            d="M19241.9 4871.6l-715.7 187.7c-93.1 24.5-149.4 120.7-125 213.8l81.4 310.2c24.4 93.1 120.6 149.4 213.8 124.9l715.7-187.7c93.2-24.4 149.4-120.6 125-213.8l-81.4-310.1c-24.4-93.2-120.6-149.4-213.8-125"
          ></path>
          <path
            fill="#bc3f3f"
            d="M19224.9 5570.5l-255.7 67.1 17.6 66.9c3 11.5 14.9 18.4 26.4 15.4l64.2-16.8 117.8-30.9 31.8-8.3c2.2-.6 4.1-1.5 6-2.6 7.8-4.9 11.9-14.5 9.5-23.9zm-647.9-11.8l-82.3-313.7c-15.9-60.8 2.3-122.8 42.7-165.5 13.3-14.2 29.1-26.3 47-35.4h.1v-.1c11.1-5.7 23-10.3 35.7-13.6l714.4-187.4c93.5-24.5 190.1 31.9 214.6 125.4l82.3 313.8c24.5 93.5-31.9 190.1-125.4 214.6l-246 64.5 17.5 66.9c8.2 31-10.5 62.7-41.4 70.8l-26.9 7.1-149.7 39.2-37.2 9.8c-14.9 3.9-30.5 1.7-43.9-6.1-13.4-7.8-23-20.4-26.9-35.3l-17.5-66.9-142.5 37.4c-5.7 1.5-11.3 2.6-16.9 3.5h-.2c-57.2 9.2-114-11.1-152.9-50.6v-.1c-20.9-21.1-36.6-47.7-44.6-78.3"
          ></path>
          <path
            fill="#ffb27d"
            d="M19142.7 5672.3s31.8 53.5 19.9 72.2c-11.9 18.7-54.8-55.5-54.8-55.5s29.3 63.7 18.4 74.2c-11 10.5-55.9-62.7-55.9-62.7s28 61.9 18.2 64c-9.8 2.1-43.1-52.1-43.1-52.1s17.3 46.4 1.5 44.1c-15.8-2.2-50-44.4-50-44.4l145.8-39.8"
          ></path>
          <path
            fill="#9db0f4"
            d="M19344.6 6442.9c-20.7-79.5-49.9-193.7-77.6-302.3-17.8 50.2-40.5 130.1-54.8 239.7-26.4 202.1 54.7 632.9 54.7 632.9l77.7-570.3"
          ></path>
          <path
            fill="#ffb27d"
            d="M20845.9 5923.2s126.9-103.1 142.6-135.3c15.6-32.2 55.1-151.4 53-189.8-2.1-38.5-97.2 87.9-125.2 98.1-27.9 10.2 29.2-51.9 22.8-65.2-6.4-13.3-66 30.2-88.7 44.4-22.7 14.2-38.6 110.6-72.5 163.1-33.8 52.4 68 84.7 68 84.7"
          ></path>
          <path
            fill="#233862"
            d="M19180.9 2836.8h632.6c45.3 30.7-12.2 331.2-12.2 331.2s-172.7 65.6-202.5-67.7c-29.9-133.1-219.9-175.4-320.5-191.7-68.8-11.2-90.6-49-97.4-71.8"
          ></path>
          <path
            fill="#233862"
            d="M20119.1 2836.8h632.7c45.3 30.7-12.3 331.2-12.3 331.2s-172.6 65.6-202.5-67.7c-29.9-133.1-219.9-175.4-320.4-191.7-68.9-11.2-90.6-49-97.5-71.8"
          ></path>
          <path
            fill="#2b478b"
            d="M19351.1 5902.7s28.4-354.8 61.8-781.3c45.4-579.3 168-2015.6 168-2015.6s80.3-68.5 258.2-31c0 0 151.4 495.5 196.7 1061 .7 9.3 1.4 18.6 2.1 28 42.2 575.7 107.7 1788 107.7 1788l-794.5-49.1"
          ></path>
          <path
            fill="#233862"
            d="M19341.4 6025.3s38.1-477.4 71.5-903.9l622.9-985.6c.7 9.3 1.4 18.6 2.1 28 42.2 575.7 107.7 1788 107.7 1788l-804.2 73.5"
          ></path>
          <path
            fill="#2b478b"
            d="M19522.9 6025.3s451.8-1124.6 596.2-1550.9c102.9-303.6 393-1368.8 393-1368.8s126.2-38.4 232.2 35.8c0 0 108 1088.3-110.1 1395.2-115.7 162.8-28.7 1692.3-497.7 1705.4-469.1 13.1-613.6-216.7-613.6-216.7"
          ></path>
          <path
            fill="#aec3ff"
            d="M20383.8 5923.2c-188.3-106.3-995.4-103.9-1032.7-20.5-83.5 186.3-104.5 1132.8-65.1 1423.1 0 0 20.4 446.2 65.1 426.5 298 137 780.5 12.6 780.5 12.6 130.1-38.3 119-335.6 155.3-753.1 4.6-53.8 9.8-109.8 15.6-167.8 25.7-257.8 63.1-553.9 119.8-866.3 3.8-20.6-10.5-38.8-38.5-54.5"
          ></path>
          <path
            fill="#ffb27d"
            d="M19908.7 7815.1c-5-79.8-109.1-70.8-234.2-42.2-125.1 28.5-79.8 102.3-79.8 102.3s1.8 56.7 9.7 124.7c2.9 24.7 6.5 51 11.3 76.5 2.2 12.4 4.6 24.8 7.4 36.6 16.4 70.3 250 2.5 248.4-16.4-4.6-51 32.3-218.8 37.2-281.5"
          ></path>
          <path
            fill="#233862"
            d="M19640.5 7675.8l-9.9 110.3 138.5-26.2-53.3-98.4-75.3 14.3"
          ></path>
          <path
            fill="#9db0f4"
            d="M19597.6 7927.1l-82.8-125.9s0-77.4 71.9-133.6l113.2 105.4s-73.2 27.9-102.3 154.1"
          ></path>
          <path
            fill="#9db0f4"
            d="M19699.9 7773s23.4-84.2 68.7-122.8c0 0 176.8 47.5 218.9 138.1l-92 118.8s-4.5-89.9-195.6-134.1"
          ></path>
          <path
            fill="#e2915a"
            d="M19872.8 8056.9s-112.5-74.8-268.4-57c2.9 24.7 6.5 51 11.3 76.5 30.7 7.4 56.5 12.1 56.5 12.1l200.6-31.6"
          ></path>
          <path
            fill="#ffb27d"
            d="M19944.2 8141.9c-55-151.3-255.7-112.7-341.1-88.4-24.1 6.8-39 12.5-39 12.5-26.4 12-34.6 58.1-32.2 116.3 4.5 115.2 50 277.8 76.1 317 39.3 59 104.8 118.9 299.5 35.4s107.2-198.9 36.7-392.8"
          ></path>
          <path
            fill="#233862"
            d="M20094.5 8219.6c-61.4-50.1-193.4-142.8-193.4-142.8s14.7 17.4 32.6 67.4c2.1 5.7 2.7 14 2.4 22.8-.7 24.5 5.2 49 18.1 69.9 8 13.1 10.9 26.6.8 36.2-16.7 15.8-45.2 1.3-63.9-11.5-12.3-8.4-28.4-8.9-41.1-1.1-.1.1-.2.1-.3.2-52.4 32.9 5.5 90.8-37.1 138.9-42.8 48.1-227.4 54.7-220 84.8-63 35.6-23.3 167-22.1 174 0 0 90.4-8 203.4 20.2 91.7 23 192.8-35.9 224.7-60.1 0 5.8-.9 13.8-5.1 22.4 0 0 13.9-13.7 13.7-29.7 1.4-1.4 2-2.4 1.6-2.7 0 0 18.2.4 28.9-6.7 0 0-7.8 2.5-11.5-2.2-.5-.5-1-.8-1.6-1.2 122.7-86.7 120.9-337.1 69.9-378.8"
          ></path>
          <path
            fill="#fff"
            d="M19654 8163.8s-9.9-19.1-59.5-3.3c0 0-41.7 13.3-34.8 35.3 0 0 45 5.5 94.3-32"
          ></path>
          <path
            fill="#2a4e96"
            d="M19640.5 7675.8s-110.6-1396.8-109-1513.2c1.5-116.4 122.5-239.4 122.5-239.4s96.3 105.5 115.1 216.8c18.8 111.3-53.3 1521.5-53.3 1521.5l-75.3 14.3"
          ></path>
          <path
            fill="#aec3ff"
            d="M20131.7 7764.9s178.4-42.7 319.5-313.7 252.5-817.6 319.8-961.8c67.4-144.3 113.6-598.6 113.6-598.6l-106.7-52.3s-257.4 465.6-295.5 618.3c-20.6 82.6-335.4 743.9-335.4 743.9l-15.3 564.2"
          ></path>
          <path
            fill="#9db0f4"
            d="M20383.8 5923.2h-.1c-336 48.3-239.7 1160.3-213.4 1164.7 26.4 4.4 123-198.8 123-198.8l9.2-45.1c25.7-257.8 63.1-553.9 119.8-866.3 3.8-20.6-10.5-38.8-38.5-54.5"
          ></path>
          <path
            fill="#233862"
            d="M24575.8 3494.1s139.9-127.7 112.6-218.4c-27.3-90.7-153.6-151.2-170.6-262.1-17.1-110.8-85.3-171.3-146.7-171.3h-292.8s-45.2 78 63.9 101.5c109.2 23.5 150.4 153.8 143.6 274.8-6.8 120.9 10.2 282.2 10.2 282.2l232 53.7 47.8-60.4"
          ></path>
          <path
            fill="#233862"
            d="M22859.6 3163.9s25.1-290.6-36.4-320.8l-785.9 1.6s0 90.7 126.2 110.9c126.3 20.1 348 94 385.6 258.7 37.5 164.6 310.5-50.4 310.5-50.4"
          ></path>
          <path
            fill="#2a4e96"
            d="M22196 7268s41.4-509.3 90.2-1121.5c66.2-831.6 145.8-1853.1 153.7-2046.6 13.6-336 68.3-873.5 68.3-873.5s129.6-127.7 388.9-73.9c0 0 231.2 767.3 297.2 1579.2 1 13.3 2 26.7 3.1 40.1 61.4 826.5 156.9 2566.8 156.9 2566.8L22196 7268"
          ></path>
          <path
            fill="#233862"
            d="M22196 7268s41.4-509.3 90.2-1121.5l908.1-1414.8c1 13.3 2 26.7 3.1 40.1 61.4 826.5 156.9 2566.8 156.9 2566.8L22196 7268"
          ></path>
          <path
            fill="#2a4e96"
            d="M22385.3 7207.5s747.2-1844.4 1238.5-2711.2c491.4-866.8 644.9-1108.7 644.9-1108.7s281.5 110.9 307.1 186.5c0 0-187.9 1673.4-516.9 2081.3-185.9 230.5-346.3 741.9-338.3 983.9 13.9 417.3-142.8 900.9-142.8 900.9l-1192.5-332.7"
          ></path>
          <path
            fill="#ffb27d"
            d="M21788.6 7180.4s-139.5-188.9-149.8-239.3c-10.3-50.4-22.3-230.4-5.6-283.1 16.7-52.7 103.7 157.2 138.9 181.4 35.2 24.2-22-82.6-8.3-98.8 13.7-16.3 80.9 65.6 107.4 93.5 26.5 28 14 167.8 42.3 252.9 28.2 85.1-124.9 93.4-124.9 93.4"
          ></path>
          <path
            fill="#fff"
            d="M22403.9 9925.8s-188.5-1755.1-205.3-1805.1c-3.7-10.9-18.5-66.5-39.3-146.4-29.7-114.1-71.6-278.2-111.4-434.1-60-235.7-124.8-439.5-124.8-439.5s-28.5-18.4-97.1-12.5c-46.9 4-72.2 44-72.2 44s6.9 861.7 49 1058.1c42.2 196.5 176.8 1403.1 244.9 1565.5 67.9 162.4 356.2 170 356.2 170"
          ></path>
          <path
            fill="#dceaff"
            d="M22159.3 7974.3c-29.7-114.1-71.6-278.2-111.4-434.1-25.6 72.1-58.2 186.9-78.8 344.2-37.9 290.5 78.6 909.2 78.6 909.2l111.6-819.3"
          ></path>
          <path
            fill="#fff"
            d="M23652.3 7227.7c-270.6-152.6-1430-149.2-1483.7-29.4-119.9 267.6-150 1627.4-93.4 2044.5 0 0 29.3 641 93.4 612.8 428.3 196.7 1121.4 18 1121.4 18 186.9-55 170.9-482.1 223-1081.9 6.7-77.2 14.2-157.8 22.5-241 36.9-370.4 90.6-795.9 172.1-1244.7 5.5-29.5-15.1-55.7-55.3-78.3"
          ></path>
          <path
            fill="#ffb27d"
            d="M22969.8 9945.7c-7.2-114.6-156.7-101.6-336.6-60.6-179.7 40.9-114.6 147-114.6 147s2.6 81.5 14 179.1c4.1 35.4 9.3 73.2 16.1 109.8 3.2 18 6.7 35.6 10.8 52.7 23.5 100.9 359.1 3.5 356.7-23.6-6.5-73.3 46.5-314.3 53.6-404.4"
          ></path>
          <path
            fill="#233862"
            d="M22584.4 9745.6l-14.2 158.4 199-37.5-76.6-141.5-108.2 20.6"
          ></path>
          <path
            fill="#dceaff"
            d="M22522.8 10106.7l-118.9-180.9s0-111.3 103.2-192l162.6 151.4s-105.1 40.1-146.9 221.5"
          ></path>
          <path
            fill="#dceaff"
            d="M22669.7 9885.2s33.7-120.9 98.8-176.4c0 0 253.9 68.3 314.5 198.3l-132.3 170.8s-6.4-129.2-281-192.7"
          ></path>
          <path
            fill="#e2915a"
            d="M22918.1 10293.1s-161.6-107.4-385.5-81.9c4.1 35.4 9.3 73.2 16.1 109.8 44.2 10.8 81.2 17.5 81.2 17.5l288.2-45.4"
          ></path>
          <path
            fill="#ffb27d"
            d="M23020.7 10415.2c-79-217.4-367.3-161.9-490-127-34.6 9.8-56 18-56 18-37.9 17.2-49.7 83.4-46.3 167 6.5 165.5 71.9 399.2 109.3 455.5 56.5 84.7 150.6 170.8 430.2 50.9 279.7-120 154.1-285.8 52.8-564.4"
          ></path>
          <path
            fill="#bc3f3f"
            d="M23236.7 10526.9c-88.3-72-277.9-205.2-277.9-205.2s21.1 24.9 46.9 96.8c2.9 8.1 3.8 20.1 3.4 32.7-1 35.3 7.5 70.4 26 100.5 11.5 18.7 15.7 38.2 1.1 52-24 22.7-64.9 1.8-91.7-16.6-17.7-12.1-40.9-12.8-59.1-1.5-.1.1-.2.2-.4.3-75.4 47.2 7.9 130.4-53.4 199.5-61.4 69-326.6 78.5-315.9 121.8-90.6 51.2-33.5 240-31.9 250 0 0 130-11.5 292.2 29 131.8 33.1 277-51.5 323-86.4 0 8.4-1.4 20-7.4 32.2 0 0 20-19.7 19.6-42.7 2-2 2.9-3.4 2.3-3.7 0 0 26.2.5 41.5-9.7 0 0-11.2 3.6-16.5-3.1-.7-.8-1.4-1.3-2.3-1.8 176.3-124.5 173.7-484.3 100.5-544.1"
          ></path>
          <path
            fill="#fff"
            d="M22603.8 10446.6s-14.2-27.3-85.5-4.6c0 0-59.8 19-49.9 50.6 0 0 64.6 7.9 135.4-46"
          ></path>
          <path
            fill="#2a4e96"
            d="M22584.4 9745.6s-158.8-2006.7-156.6-2173.9c2.2-167.2 176-344 176-344s138.4 151.6 165.4 311.5c27.1 160-76.6 2185.8-76.6 2185.8l-108.2 20.6"
          ></path>
          <path
            fill="#dceaff"
            d="M23652.3 7227.7h-.2c-482.7 69.5-344.4 1667-306.5 1673.3 37.9 6.3 176.7-285.6 176.7-285.6l13.2-64.7c36.9-370.4 90.6-795.9 172.1-1244.7 5.5-29.5-15.1-55.7-55.3-78.3"
          ></path>
          <path
            fill="#a02e2e"
            d="M25071.2 6037.7l-1020.7-297c-132.8-38.6-273.1 38.4-311.8 171.3l-128.7 442.3c-38.6 132.8 38.4 273.1 171.3 311.8l1020.6 297c132.9 38.7 273.2-38.4 311.8-171.2l128.8-442.3c38.6-132.9-38.4-273.2-171.3-311.9"
          ></path>
          <path
            fill="#bc3f3f"
            d="M24534.3 6886.6l-364.6-106.2-27.8 95.5c-4.7 16.4 4.8 33.7 21.2 38.5l91.6 26.6 167.9 49 45.4 13.1c3.1.9 6.2 1.3 9.2 1.2 13.3-.2 25.4-9 29.3-22.3zm-789.8-492.9l130.2-447.4c25.2-86.6 93.5-149.6 174.7-172.5 27-7.6 55.3-10.8 84.1-8.9h.2c17.9 1.2 36 4.2 54 9.5l1018.8 296.5c133.4 38.8 210.7 179.6 171.9 313l-130.2 447.4c-38.8 133.4-179.6 210.7-313 171.9l-350.8-102.1-27.7 95.5c-12.8 44.1-59.2 69.5-103.3 56.7l-38.5-11.2-213.4-62.1-53-15.5c-21.2-6.1-38.9-20.4-49.7-39.9-10.7-19.5-13.2-42.1-7-63.3l27.8-95.5-203.3-59.1c-8-2.3-15.8-5-23.4-8.1l-.1-.1h-.1c-77.3-31-132.3-97.9-151.1-175.3v-.1c-10.1-41.4-9.8-85.7 2.9-129.4"
          ></path>
          <path
            fill="#ffb27d"
            d="M24228.5 7172.2s-31.6-264.6 27.7-260.8c59.3 3.8 204.5 32.2 214.4 77.8 9.9 45.6-111.2 258.2-111.2 258.2l-130.9-75.2"
          ></path>
          <path
            fill="#ffb27d"
            d="M24236.1 7172.9s-45.4-56.4-49.3-100.8c-3.9-44.4-25.6-122-5.2-127 20.3-5 57.5 96.6 57.5 96.6l-3 131.2"
          ></path>
          <path
            fill="#fff"
            d="M23290.1 9873.6s256.3-61.4 459-450.7c202.6-389.3 362.7-1174.5 459.5-1381.8 96.7-207.2 170.4-829.1 170.4-829.1s-15.1-30.9-67.3-42.2c-40.4-8.7-82-5.3-82-5.3s-381.1 610.5-435.8 829.8C23764.3 8113 23312 9063 23312 9063l-21.9 810.6"
          ></path>
          <path
            fill="#ffb27d"
            d="M6407.3 3095.6l-38.4 276.7 161.34 134.8 50.82-366.3-173.76-45.2"
          ></path>
          <path
            fill="#233862"
            d="M6943.1 2848.5h-657.78s-15.76 100.3 18.59 176.3c32.93 73 84.16 118.1 88.83 174 41.8-43.1 93.23-70.8 188.32-58 11.66-18.9 75.22-120 114.14-147.9 42.99-30.9 234.66-19.9 247.9-144.4"
          ></path>
          <path
            fill="#f99746"
            d="M6810.94 6831.2c-43.02-346-78.2-672.3-106.85-976.3-153.08-1620.1-123.04-2610.3-123.04-2610.3s-11.06-29.3-105.97-29.7c-53.64-.3-106.18 11.4-106.18 11.4s-342.75 1057.9-316.29 1659.9c3.15 72.4 11.65 138.2 26.75 194.9 82.22 308-304.27 1485.9 115.86 1924.6 420.05 438.6 635.5-15.7 615.72-174.5"
          ></path>
          <path
            fill="#ed7d2b"
            d="M6810.94 6831.2c-43.02-346-78.2-672.3-106.85-976.3l-651.48-968.7c3.15 72.4 11.65 138.2 26.75 194.9 82.22 308-304.27 1485.9 115.86 1924.6 420.05 438.6 635.5-15.7 615.72-174.5"
          ></path>
          <path
            fill="#ffb27d"
            d="M7328.63 10131.9s-15.85 79.1-33.7 139.4c-7.9 26.7 47.16 202.1 85.26 223.6 38.11 21.6 137.35-96.5 109.27-187.1-14.43-46.6-38.13-198.2-38.13-198.2l-122.7 22.3"
          ></path>
          <path
            fill="#ffb27d"
            d="M7328.63 10131.9s-20.71 45.9-39.42 53.3c-18.71 7.4-38.76 82.4-52.49 112.1 0 0 13.26 19.4 36.47-5.6 14.16-15.2 45.72-60 45.72-60l9.72-99.8"
          ></path>
          <path
            fill="#ffb27d"
            d="M4613.23 9866c-4.03-8.3-9.64-23.2-14.63-37.2-.08 3.8-.14 7.6-.18 11.3-.26 27.8-104.24 179.4-146.98 188.9-42.76 9.5-103.1-132.5-49.7-211 27.43-40.3 94.45-178.3 94.45-178.3l110.8 57.2s6.39 49.9 22.1 62.5c15.71 12.6 12.95 90.1 17.38 122.6 0 0-18.37 14.7-33.24-16"
          ></path>
          <path
            fill="#ffb27d"
            d="M5304.16 3178.6c-9.3 88.6-11.6 321.9-11.6 321.9s237.71 76 228.96 23.8c-43.01-256.5-31.33-383.5-31.33-383.5l-96.13-57.4-89.9 95.2"
          ></path>
          <path
            fill="#f99746"
            d="M6612.41 6847.7s-918.32-2517.3-1099.09-3609.2c0 0-41.88-28.8-120-19.2-88.6 10.8-112.88 72.4-112.88 72.4s-165.9 1308.7 163.84 1763c226.54 312.2-97.71 1683.1 429.92 1793 527.64 109.9 738.21 0 738.21 0"
          ></path>
          <path
            fill="#163560"
            d="M6449.3 9269.2l-.01.1c-.16 1.1-.42 2.1-.76 3.2-5.37 22.2-11.23 44-17.54 65.6l-.01.1c-19.22 66.2-42.09 129.2-66.76 184.9-14.21 31.9-28.92 61.5-43.98 87.8-2.3 4.1-4.55 7.8-6.71 11.7l-.11.1c-35.49 59.7-71.97 100.9-104.88 113.6-60.04 23.2-126.83 16.8-172.81 7.2 10.09 14.1 32.98 22 32.98 22-29.43.1-89.36-38-89.36-38-422.96 94.6-398.24-503.7-369.44-639.3 28.7-135.6-107.48-201.5-299.53-340.3-366.51-264.7-360.38-545.4-360.38-545.4 3.31-1.4 6.92-3 10.66-4.9 14.75 36.3 46.03 105.1 78.82 119.6-.93-3.2-27.6-92.3-35.17-142.1 55.83-30.8 133.09-78.4 216.98-132.2l.11-.1c112.25-71.8 236.14-154.5 335.42-221.6-4.72 27.3-9.39 66.3-2.45 88.3l31.63-98 4.15-12.8c63.36-42.9 113.35-77.3 138.73-94.9 2.6-1.8 4.91-3.4 6.98-4.8 7.42-5 11.39-7.8 11.39-7.8-8.43 34.6 28.55 124.9 90.61 238.7 4.25 7.9 8.76 16 13.36 24.2 20.04 36 42.41 73.9 66.49 113 8.67 14.1 17.44 28.2 26.54 42.6-.01.1.08.1.08.1 7.28 11.6 14.65 23.1 22.28 34.8l.07.1c17.99 27.8 36.59 55.8 55.57 83.8 49.16 72.6 101.21 144.7 152.65 210.8 74.69 96.3 147.98 180 209.35 234.8 2.01 4.1 3.91 8.3 5.62 12.6 9.61 22.3 16.98 47.1 22.69 72.4 1.78 7.5 3.36 15.2 4.75 22.8 15.3 80.5 15.6 160.4 15.4 179.3 4.91-77.7 2.96-136.4-2.25-180.7-1.18-9.8-2.46-18.8-3.94-27.2h.02c-3.27-19-7.04-34.6-11.11-47.6 10.05 12.5 18.89 26.1 26.69 41.1 5.11 9.7 9.77 20 13.89 30.7 49.5 126.4 36.18 314.9-6.71 491.7"
          ></path>
          <path
            fill="#ffb27d"
            d="M6369.84 8663c1.42-.4-118.41-87.1-220.06-74.9-95.61 11.4-173.36 121.6-172.13 122 37.76 13.5 41.02 68.7 6.02 298.3l18.11-.8 304.47-14.6s7.11-94.1 19.96-181.8c10.75-73.6 25.6-142.8 43.63-148.2"
          ></path>
          <path
            fill="#ed975d"
            d="M6306.25 8993s7.11-94.1 19.96-181.8c-166.2-19.9-276.14 118-324.43 196.4l304.47-14.6"
          ></path>
          <path
            fill="#163560"
            d="M5966.44 9035.2s-107.11 124.2-145.36 238.3c-27.31 81.4-30.83 397.8 287.44 399.5 0 0 91.84 4.6 185.45-40.2 74.46-35.7 186.78-119.8 117.86-406.6l-14.77-95.7-430.62-95.3"
          ></path>
          <path
            fill="#fff"
            d="M6849.31 6896s-396.21-235.4-893.66-114.4c-5.86 1.5-409.61 65.9-409.61 65.9 6.93 19 59.11 330.9 65.94 348.3 88.25 227.8 249.54 1463.8 384.3 1524.8 34.41-37.5 132.75-63.1 216.92-55.7 96.92 8.6 129.37 50.5 121.33 95.5 107.25-50.7 189.66-97.8 189.66-97.8 98.03-146.9 325.12-1766.6 325.12-1766.6"
          ></path>
          <path
            fill="#ffb27d"
            d="M6373.87 8918.2s-390.62-64.7-427.17 179.2c-36.55 243.8-111.58 399.7 136.27 451.4 247.86 51.6 310.37-34.2 342.37-112.6 32-78.5 49.2-494.9-51.47-518"
          ></path>
          <path
            fill="#163560"
            d="M6260.42 9581.3s-64.64-302.1-222.89-368.9c-158.24-66.8-236.17-33.3-236.17-33.3s111.3 119 111.46 297.9c0 0 246.47 228.4 347.6 104.3"
          ></path>
          <path
            fill="#163560"
            d="M6237.31 9554.1s89.57-52 118.33-111.2c28.76-59.2 56.56-185.8 93.18-175.9 0 0 51.84 206.5-55.3 303.1-113.61 102.3-156.21-16-156.21-16"
          ></path>
          <path
            fill="#2b478b"
            d="M7454 10126.3c-15.88 5.6-39.3 18.8-69.83 21.6-27.2 2.4-61.38-6.5-61.38-6.5s-110.2-685-118.39-721.3c-8.18-36.3-472.7-559.6-597.94-557.5-113.09 1.9-247.07-85.4-271.93-102.2 0 0 263.37-556.9 276.5-661.5 14.29-113.8 150.63-1866.8 150.63-1866.8s-8.11-81.5 144.26-37.7c152.36 43.9 190.47 80.1 190.47 80.1s-139.96 1380.1-169.32 1809.2c-3.94 57.6-13.35 111-26.74 160.2-12.91 47.4-2.73 98.1 26.22 137.8 192.63 264.5 496.84 692.9 513.79 784 24.9 133.8 13.66 960.6 13.66 960.6"
          ></path>
          <path
            fill="#233862"
            d="M5852.22 2848.5h-657.78s-15.77 100.3 18.59 176.3c32.92 73 84.16 118.1 88.83 174 41.8-43.1 93.23-70.8 188.33-58 11.65-18.9 75.21-120 114.13-147.9 42.98-30.9 234.66-19.9 247.9-144.4"
          ></path>
          <path
            fill="#2b478b"
            d="M6304.07 8044.8c-22.16 136.4-305.2 684.2-305.2 684.2s-178.93 53.5-296.7 10.8c-117.76-42.6-727.07 301.4-746.6 333.1-19.53 31.6-345.88 639.7-345.88 639.7s-27.06 15.7-70.42-6.9c-42.89-22.3-43.08-66-43.08-66s251.87-765.6 318.88-884.1c40.41-71.4 380.13-320.1 643.52-506.3 50.29-35.5 76.7-96.1 68.44-157.1-35.41-261.8-44.43-409.3-85.16-858.5-33.79-372.7-46.72-1094.3-46.72-1094.3s294.74-102 498.16-115.5c203.42-13.6 247.75 200.9 247.75 200.9s185.8 1679.7 163.01 1820"
          ></path>
          <path
            fill="#fff"
            d="M6245.87 9038.8s47.46-27.9 103.49-19.5c38.38 5.8 47.1 51.3 47.1 51.3s-26.68 8.4-69.26-.2c-42.58-8.5-81.33-31.6-81.33-31.6"
          ></path>
          <path
            fill="#ffb27d"
            d="M18722.4 13687.8s-182.7 324.8 0 390.9c182.8 66.1 265.5 5.1 274.2-68.8 8.7-74-69.6-365.6-69.6-365.6l-204.6 43.5"
          ></path>
          <path
            fill="#f99746"
            d="M18868.9 11610.4c-333.2-60.1-639.6-87.9-916.2-94.1-262.5-5.9-498 7.7-704.2 31.7-55.4 6.3-108.6 13.5-159.7 21.2-539.5 81.8-837.5 226.9-837.5 226.9s493 1032 891.3 992.4c467.2-46.6 1258.9-465.9 1337.6-348.3 88.6 132.5 162.8 1268.7 162.8 1268.7 207.1 141.8 330.8-7.7 330.8-7.7s404.7-1998.9-104.9-2090.8"
          ></path>
          <path
            fill="#ed7d2b"
            d="M18868.9 11610.4c-333.2-60.1-639.6-87.9-916.2-94.1-262.5-5.9-498 7.7-704.2 31.7-124.3 395.3-213.7 799.1 36.3 555.6 441-429.3 1584.1-493.2 1584.1-493.2"
          ></path>
          <path
            fill="#233862"
            d="M16213.9 3278.2s-35.4-410.3 51.3-452.9l1109.8 2.2s0 128.1-178.3 156.6c-178.2 28.4-491.4 132.8-544.4 365.2-53 232.5-438.4-71.1-438.4-71.1"
          ></path>
          <path
            fill="#2a4e96"
            d="M17028.7 8243.4c-5.5-268.2-18.4-544.9-43.6-793.6-119.9-1180-162-2605.8-179.2-2880.7-29.9-477.1-124.1-1239.4-124.1-1239.4s-187-177.5-551.5-92.8c0 0-301.9 1097.9-369.4 2254-1 19-2 38.1-3 57.1-7.8 152.9-16 327.6-24.4 516.1-55.5 1262.3-115.8 3137.4-115.8 3137.4l1392.6 327s32.1-616.3 18.4-1285.1"
          ></path>
          <path
            fill="#233862"
            d="M17028.7 8243.4c-353-608.9-1025.7-1730.8-1295.2-2179.3-55.5 1262.3-115.8 3137.4-115.8 3137.4l1392.6 327s32.1-616.3 18.4-1285.1"
          ></path>
          <path
            fill="#ffb27d"
            d="M13502.4 9423s-226.6-266.5-247-340.4c-20.4-73.9-56.7-340.1-37.2-420.1 19.4-80 170.1 223.1 224.9 255.4 54.8 32.4-41.1-120.5-22.4-146 18.7-25.5 126.9 89.2 169.1 128.1 42.2 38.8 38 247.8 88.7 371.4 50.7 123.6-176.1 151.6-176.1 151.6"
          ></path>
          <path
            fill="#233862"
            d="M13449.3 3744.4s-197.6-180.2-159-308.3c38.5-128.1 216.8-213.5 240.9-370 24.1-156.6 120.4-242 207.1-242h413.5s63.8 110.2-90.4 143.4c-154.1 33.2-212.3 217.2-202.6 387.9 9.6 170.8-14.5 398.5-14.5 398.5l-327.6 75.9-67.4-85.4"
          ></path>
          <path
            fill="#2a4e96"
            d="M16790.9 9172.9s-1303.7-2789.4-1997.5-4013.3c-693.7-1223.9-910.5-1565.4-910.5-1565.4s-397.5 156.5-433.6 263.3c0 0 265.3 2362.8 729.9 2938.8 262.5 325.4 570.9 1302.1 585.3 1643.7 14.5 341.6 319 1156.8 319 1156.8l1707.4-423.9"
          ></path>
          <path
            fill="#f99746"
            d="M17172 9041.4s-518.4-346.6-1252.2-314.4c-322 14-960.7 105.3-1063.8 361.4-24.2 60.2-6.5 136.5 29.8 253.6 94.3 304.4 313.9 884.1 247.6 2169.4-6.2 119.5-2.8 232 8.6 337.2 69.1 633.1 431.9 1008 782.6 1130.5 855.2 299 1140.3-115.2 1140.3-115.2s378-299.5 435-924.5c113.9-1250.2-327.9-2898-327.9-2898"
          ></path>
          <path
            fill="#ffb27d"
            d="M16175.2 13050.9s392.4-238.6 818-171.1c0 0 84.2 21.4 42.8 120.1 25.9 79.8 22.8 72.6 37.8 131.8 37.3 136.4 48.7 215.1 48.7 215.1l-241.5 253.8-228.8 242.6c-63.2-579.8-477-792.3-477-792.3"
          ></path>
          <path
            fill="#ed985f"
            d="M16615.6 13623.3s131.4-351.3 458.2-491.6c37.3 136.4 61.6 279 61.6 279l-254.4 189.9-265.4 22.7"
          ></path>
          <path
            fill="#ffb27d"
            d="M17393.5 13359s-749-93.6-801.6 366.7c-52.6 460.3-184.7 758.2 291.3 837.4 476 79.3 589.2-86.2 644.7-235.7 55.5-149.4 59.1-932.2-134.4-968.4"
          ></path>
          <path
            fill="#2c3a64"
            d="M16355.4 14637.9c-6.5 8-21.8 14.9-56.4 18.1 0 0 28.4 16.3 65.5 15.8 9 48 37.9 132.4 121.7 150 0 0-45.5-22.1-53.2-73.2 99.8 95.1 308.6 159.2 877.4 92.6 749.7-87.8 483.7-559.8 384.1-613.5-99.5-53.7-338.5 112.9-520.4 105.4-181.9-7.6-226.4-268.3-289.2-302-62.9-33.6-36.3 29.1-118.1 69.8-81.9 40.8-95-96.8-74.3-187.4 20.6-90.6-98.2-204.6-98.2-204.6l-110.9 128.9c-110.9 128.8-199.9 660.2-143.1 770.6 5 9.7 10 19.6 15.1 29.5"
          ></path>
          <path
            fill="#2a4e96"
            d="M18643 13903.3l-1271.6-11.4-8.7 191.3 1267.6 98.3 12.7-278.2"
          ></path>
          <path
            fill="#2a4e96"
            d="M19602.9 13871.3l-1384.5-20-15.8 347.8 1380.5 107 19.8-434.8"
          ></path>
          <path
            fill="#233862"
            d="M20536.4 13844l-1167.5-1.4-21.3 469.5 1162.7 105.8 26.1-573.9"
          ></path>
          <path
            fill="#233862"
            d="M20553.1 13764.8c36 1.6 63.9 32.1 62.2 68.1l-27.4 603.2c-1.6 36-32.1 63.9-68.1 62.3-36.1-1.7-63.9-32.2-62.3-68.2l27.4-603.2c1.6-36 32.2-63.9 68.2-62.2"
          ></path>
          <path
            fill="#2a4e96"
            d="M17367.4 13851.1c-32-1.4-59.1 23.3-60.6 55.3l-7.1 156.5c-1.5 32.1 23.3 59.2 55.3 60.7 32.1 1.4 59.2-23.4 60.6-55.4l7.1-156.5c1.5-32-23.3-59.1-55.3-60.6"
          ></path>
          <path
            fill="#ed7d2b"
            d="M14885.8 9342c94.3 304.4 313.9 884.1 247.6 2169.4-6.2 119.5-2.8 232 8.6 337.2h.1c281.4 24.2 711.7-280 711.7-280s-265-1615.4-968-2226.6"
          ></path>
          <path
            fill="#f99746"
            d="M16175.2 13050.9s-721.7-124-1098.1-583.5c-376.4-459.5-981.9-1107.4-1125-1334.3-143.2-226.9-540.6-1771-540.6-1771s26.3-49.6 135.9-78.5c104.9-27.7 181 15.2 181 15.2s526.5 901.1 724.7 1351.3c82.3 187 1506.2 927.7 2041.5 850.9 210.2-30.1-319.4 1549.9-319.4 1549.9"
          ></path>
          <path
            fill="#ffb27d"
            d="M18635.4 14236.3s155.2-95 248.1-54.8c92.8 40.2 84.6 76.9 84.6 76.9s-280.5 77.9-332.7-22.1"
          ></path>
          <path
            fill="#bc3f3f"
            d="M16875.5 12547.6s-316.7-1999.6-2563.8-2581.8l-244.8-482.9 430-72.8s2558.3 741.2 2523.8 3172.8l-145.2-35.3"
          ></path>
          <path
            fill="#a02e2e"
            d="M16826.4 12866.7l-103.7-10.1v-150.2l152.8-158.8 145.3 35.3v208.4l-104.5 75.4h-89.9"
          ></path>
          <path
            fill="#ed7d2b"
            d="M16419.4 13264.3s-100.4-22.6-330-213.4c0 0 228.3-484.5 709.9-542.5 0 0-7.7 224.8 27.1 358.3 0 0-381.7 136.7-407 397.6"
          ></path>
          <path
            fill="#ed7d2b"
            d="M16826.4 12866.7s235-124.8 252.4-319.1c0 0 89.8 142.3 63.8 240.8-26.1 98.6-106.6 211.5-106.6 211.5s8-118.7-209.6-133.2"
          ></path>
        </g>
      </g>
    </svg>
  );
}