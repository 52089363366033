import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {Route, Routes, BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './store';
import {pageurl} from './utils/constants';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import loader from './extras/loader/loader.svg';
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';
import Solutions from './components/solutions';
import './index.scss';
import Notification from './components/notification';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins',
  }
});

const RouteList = () => (
  <Router>

    <Notification />

    <Suspense fallback={ <div className="loader-container"><img src={ loader } alt="loader icon" /></div> } >  

      <Routes>
        <Route path={pageurl.HOMEPAGE} element={<Home />} />
        <Route path={pageurl.ABOUT} element={<About />} />
        <Route path={pageurl.CONTACT_US} element={<Contact />} />
        <Route path={pageurl.SOLUTIONS} element={<Solutions />} />
      </Routes>

    </Suspense>

  </Router>
);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <RouteList />
      </ThemeProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
