import React,{FC} from 'react'
import BreakPoint from '../../utils/break-point'
import './footer.scss'
import logo from '../../extras/images/logo.png'
import { Copyright } from '../../utils/copyright'
import { useNavigate } from 'react-router-dom'
import { Url } from '../../enums/route'


const Footer : FC = ( ) => {

    const navigate = useNavigate();

    return(
        <div className="footer-main">

            <div className="shade-footer"/>

            <BreakPoint>
                {/* Company Name and */}
                <div className="footer-content">

                    <div className="company_details_section div-container">
                        <div className="hold-in-place">
                            <img src={logo} alt="logo" width="100px" />
                            <div className="description_etraffika">
                                <p>
                                ChitHub Traffic System Management Company Ltd (CTSMC), is an innovative smart city technology company registered in Nigeria with expertise in implementing road use enforcement solutions using artificial intelligence and Internet of Things (IoT) technology.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Quick Menu */}
                    <div className="div-container quick-menu-section">
                        <div className="hold-in-place">
                            <div className="section-title-etraffika">
                                <h3>Quick Menu</h3>
                            </div>
                            <ul>
                                <li onClick={() => navigate(Url.home)}>Home</li>
                                <li onClick={() => navigate(Url.about)}>About Us</li>
                                <li onClick={() => navigate(Url.solutions)}>Our Solutions</li>
                                <li onClick={() => navigate(Url.contact)}>Contact Us</li>
                            </ul>
                        </div>
                    </div>

                    {/* Subscription Details */}
                    <div className="div-container contact-section">
                        <div className="hold-in-place">
                            <div className="section-title-etraffika">
                                <h3>Contact Us</h3>
                            </div>
                            <div className="address-stuff contact-data">
                                <i className="fas fa-address-card"></i>
                                <p>MM2B, Elizabeth Crescent, North Start Garden Estate <br />Lokogoma, FCT, Abuja <br/>Nigeria</p>
                            </div>
                            <div className="phone-stuff contact-data">
                                <i className="fas fa-phone"></i>
                                <a href="tel:+2348038800629">
                                    +234(803) 880‐0629
                                </a>
                            </div>
                            <div className="email-stuff contact-data">
                                <i className="fas fa-envelope"></i>
                                <a href='mailto: tech@echithub.com?subject=Contact%20Chithub' >
                                    tech@echithub.com
                                </a>
                            </div>
                            <div className="social-media-links">
                            </div>
                            <div className="form-section">
                            </div>
                        </div>
                    </div>
                    
                </div>

                <Copyright />
                
            </BreakPoint>
        </div>
    )
}

export default Footer;
