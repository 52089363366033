import { Url } from '../../enums/route';

export const MenuItems = [
    {
        title: 'Home',
        url: Url.home,
        cName: 'nav-links'
    },
    {
        title: 'About Us',
        url: Url.about,
        cName: 'nav-links'
    },
    {
        title: 'Our Solutions',
        url: Url.solutions,
        cName: 'nav-links'
    },
    {
        title: 'Contact Us',
        url: Url.contact,
        cName: 'nav-links'
    }
]