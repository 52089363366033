import React, { useEffect } from 'react';
import { IHttp } from '../interfaces/IHttp';
import { ButtonRequest } from '../utils/button';
import { validateFormValues } from '../utils/form-validation';

interface IProps {
    http: IHttp;
    loading: boolean;
    cTitle: string | JSX.Element;
    uTitle?: string;
    update: boolean | undefined;
    getAll?: ( pageNumber?: number, pageSize?: number) => void;
    formAction: ( data: object, update?: boolean ) => void;
    adminAction: string;
    formState: {[key: string]: any};
    setFormState:  React.Dispatch<React.SetStateAction<{[key: string]: any}>>;
    createInitObj: object;
    updateInitObj?: object;
    actionEnums: { create: string, update?: string };
    otherValidations: boolean;
    setFormError: React.Dispatch<React.SetStateAction<{ [ key : string ] : string; } | undefined>>;
    onButtonClick?: () => void;
}

export const useButtonRequest = ( props: IProps ) => {
    
    const { http, loading = false, cTitle, uTitle, update, getAll, formAction, formState, setFormState, createInitObj, updateInitObj, onButtonClick, actionEnums, adminAction, otherValidations, setFormError } = props;

    const isSuccess = ( ) => {
        return (update ? actionEnums.update : actionEnums.create ) === adminAction && http.httpStatus === 200
    }

    useEffect(()=>{
        if( isSuccess() ){
            if(!update){
                setFormState( createInitObj )
            }
            setTimeout(()=>{
                typeof getAll === 'function' && getAll!(1,50)
            },3000)
        }
    // eslint-disable-next-line
    },[ http.httpStatus ]);

    const handleOnSubmit = ( ) => {
        typeof onButtonClick === 'function' ? onButtonClick!() : void(0);
        validateFormValues( formState, createInitObj, setFormError )
        .then(( isValidated )=>{        
            if( isValidated ) {
                if( otherValidations)
                formAction( formState )
            }
        })
    }
    
    const handleOnUpdate = ( ) => {
        typeof onButtonClick === 'function' ? onButtonClick!() : void(0);
        validateFormValues( formState, updateInitObj, setFormError )
        .then(( isValidated )=>{        
            if( isValidated ) 
                // if( validateRegNum( vehicleInfo!?.regNumber ) )
                formAction( formState, update )
        } ) 
    }

    const BTN = 
    <ButtonRequest title={ update ? uTitle! : cTitle } isLoad={ loading } isSuccess={ isSuccess( ) } 
        onClick={()=>{ update ? handleOnUpdate() : handleOnSubmit();}} />

    return [BTN];
};