import React, { useState, FC } from "react";
import { IAppState } from '../../interfaces/IAppState';
import { connect } from 'react-redux';
import { IActions } from '../../interfaces/IActions';
// import { IContact } from '../../interfaces/IContact';
import { postContactInfo } from '../../store/actions'
import './form.scss';
import ErrorTxt from "../../utils/form-validation";
import { useButtonRequest } from "../../hooks/useButtonRequest";
import { IHttp } from "../../interfaces/IHttp";
import { naijaPhoneRegex } from '../../utils/constants';
import { ActionEnums } from "../../enums/ActionEnums";

interface IStateProps {
    actions: IActions;
	http: IHttp;
}

interface IDispatchProps {
	postContactInfo: (data: object, update?: boolean) => void;
}

interface IProps extends IStateProps, IDispatchProps {}

const ContactForm:FC<IProps> = props => {

	const { http, actions, postContactInfo } = props;
	const initObj = { fullName: '', email: '', company: '', phoneNumber: '', message: '' };
    const [ contactInfo, setContactInfo ] = useState(initObj as {[key: string]: any});
	const [ formError, setFormError ] = useState<{ [key : string] : string }>( );
	const [ otherErrors, setOtherErrors ] = useState<{ [key : string] : string }>();
    const [ oV, setOV ] = useState(false);

	const handleContactInfo = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setContactInfo( v => ({ ...v, [ name ] : value }) );
		setFormError( v => ({ ...v, [name] : '' }) );
		setOtherErrors( v => ({ ...v, [name] : '' }) );
    };

	const validatePhoneNumber = ( name:string, value : string ) => {
        if(!naijaPhoneRegex.test(value)){
            setOtherErrors(p => ({...p, [ name ] : `${name} not valid`}))
            setOV( false )
        }else{
            setOV( true )
            setOtherErrors(p => ({...p, [ name ] : ``}))
        }
    };

	const onButtonClick = () => {
        validatePhoneNumber( 'phoneNumber', contactInfo!?.phoneNumber )
    };

	const [BTN] = 
    useButtonRequest({ http: http, loading: actions.loading, cTitle: 'Submit',
    update: false, formAction: postContactInfo, adminAction: actions.action, formState: contactInfo,
    setFormState: setContactInfo, createInitObj: initObj, actionEnums: {create: ActionEnums.POST_CONTACT_INFO}, otherValidations: oV, setFormError: setFormError, onButtonClick: onButtonClick
    });

	return (
		<div className="contact-form">
			<section className="content-section">
				<h2>Contact Us</h2>
				<div className="content-details">
					<div className="input-content">
						<label htmlFor="text"><b>Full Name *</b></label>
						<input name='fullName' placeholder="John" onChange={handleContactInfo} />
						<ErrorTxt formError={ formError } value={'fullName'} />
					</div>
					<div className="input-content">
						<label htmlFor="text"><b>Email *</b></label>
						<input name="email"  placeholder="test@gmail.com" onChange={handleContactInfo} />
						<ErrorTxt formError={ formError } value={'email'} />
					</div>
					<div className="input-content">
						<label htmlFor="text"><b>Phone Number *</b></label>
						<input name="phoneNumber"  placeholder="+2349876564327" onChange={handleContactInfo} />
						<ErrorTxt formError={ otherErrors } value={'phoneNumber'} />
					</div>
					<div className="input-content">
						<label htmlFor="text"><b>Company *</b></label>
						<input name="company" placeholder="Company..." onChange={handleContactInfo} />
						<ErrorTxt formError={ formError } value={'company'} />
					</div>
					<div className="input-content">
						<label htmlFor="text"><b>Message *</b></label>
						<textarea placeholder="Message..." name='message' onChange={handleContactInfo} />
						<ErrorTxt formError={ formError } value={'message'} />
					</div>
				</div>
			</section>
			{BTN}
		</div>
	);
};


const mapStateToProps = (state: IAppState): IStateProps => ({
    actions: state.actions,
	http: state.http
});

const mapDispatchToProps: IDispatchProps = {
    postContactInfo
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);