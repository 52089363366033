import { combineReducers } from "redux";
import mainReducer from './mainReducer';
import httpReducer from './httpReducer'

const rootReducer = combineReducers({
    actions: mainReducer,
    http: httpReducer
});

export default rootReducer;
