import React from 'react'
import './index.scss'


const BreakPoint = (props : any) => {
    return(
        <div className="break-point">
            {props.children}
        </div>
    )
}

export default BreakPoint;
