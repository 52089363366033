import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Url } from '../../enums/route';
import "./index.scss";

const Contact = () => {

    const navigate = useNavigate();

    return (
        <div className="contact-content">

                <div className="left-section contact-description-section">
                    <p className="contact-landing-header">
                        Let's Talk
                    </p>
                    <p className="contact-landing-description">
                        We'd love to hear from you, we are ready to answer any questions you have.
                    </p>

                </div>
                <div className="right-section contact-description-section">
                    <button className="btn-style" onClick={() => navigate(Url.contact)}>
                        Contact Us&nbsp;&nbsp;
                        <i className="fas fa-arrow-right" />
                    </button> 
                </div>

        </div>
    );
}

export default Contact;