import React, { useState } from "react";
import { MenuItems } from './menuItems';
import { pageurl } from '../../utils/constants';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../extras/images/logo.png';
import BreakPoint from "../../utils/break-point";
import "./navbar.scss";

const Navbar = () => {
	const [clicked, setClicked] = useState(false);
	const handleClick = () => {
		setClicked(!clicked);
	}

    return (
		<BreakPoint>
			<nav className='navbarItems'>

				<Link to={pageurl.HOMEPAGE} style={{textDecoration: 'none', display: 'flex', alignItems: 'center'}}>
					<img src={logo} alt='...logo' height='50px' />
				</Link>

				<div className="menu-icon" onClick={handleClick}>
					<i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
				</div>

				<ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
					{MenuItems.map((item, index) => (
						<NavLink key={index} to={item.url}
							className={({ isActive }) =>
								isActive ? `${item.cName} nav-current` : item.cName
							}
							onClick={() => setClicked(!clicked)}>
							{item.title}
						</NavLink>
					))}
				</ul>

			</nav>
		</BreakPoint>
    );
};

export default Navbar;