import { ActionEnums } from '../../enums/ActionEnums'
import { HttpMethod } from '../../enums/HttpEnums';
// import { etraffica_baseurl } from "../../utils/constants";
import axios from "axios";
import { getHttpError, getHttpSuccess } from './httpAction';
import { AXIOS_REQUEST, AXIOS_REQUEST_SUCCESS, AXIOS_REQUEST_FAILURE, CLEAR_NOTIFICATION } from './types';

export const postContactInfo = (data: object, update?: boolean) => (dispatch: Function) => {
	dispatch({ type: AXIOS_REQUEST, payload : ActionEnums.POST_CONTACT_INFO })
	axios({url: `https://genericapis.myapiservices.net/api/v1/ContactUs/FeedBack`, method:'post', data: data, timeout: 1000*30, validateStatus : ()=> true })
	.then((res) => {
		dispatch({ type: AXIOS_REQUEST_SUCCESS })
		getHttpSuccess( dispatch, ActionEnums.POST_CONTACT_INFO, res, HttpMethod.POST )
	})
	.catch((err) => {
		dispatch({
			type: AXIOS_REQUEST_FAILURE,
			payload: err.message,
		});
		getHttpError( err, dispatch, ActionEnums.POST_CONTACT_INFO, HttpMethod.POST )
	});
};

export const clearNotification = (  ) => ( dispatch : Function ) =>{
	dispatch({ type: CLEAR_NOTIFICATION })
};