import React, {FC} from 'react'
import './topnav.scss'
import BreakPoint from '../../utils/break-point';


const TopNavBar:FC = () => {
    return(
        <div className="top-nav">
            <BreakPoint>
                <div className="top-nav-container">
                    <div>
                        <ul className="ul-style">
                            <li>
                                <a className="top-nav-content" href="tel:+2348038800629">
                                    +234(803) 880‐0629
                                </a>&nbsp;&nbsp;
                                <i className="fas fa-phone"></i>
                            </li>
                            <li>
                                <a className="top-nav-content" href='mailto: tech@echithub.com?subject=Contact%20Chithub'>
                                    tech@echithub.com
                                </a>
                                &nbsp;&nbsp;
                                <i className="fas fa-envelope"></i>
                            </li>
                        </ul>
                    </div>
                    <div className='top-nav-icons'>
                        <ul className="ul-style-2">
                            <li>
                                <i className="fab fa-linkedin-in"></i>
                            </li>
                            <li>
                                <i className="fab fa-facebook-f"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </BreakPoint>
        </div>
    )
}

export default TopNavBar;