export const pageurl = {
    HOMEPAGE: '/',
    ABOUT: '/about',
    CONTACT_US: '/contact',
    SOLUTIONS: '/solutions/:id?',
};

export const etraffica_baseurl = 'https://etraffica.azurewebsites.net/api';

export const validEmailRegex = /\S+@\S+\.\S+/;
export const naijaPhoneRegex = /^[+][0-9]\d{9,13}$/;