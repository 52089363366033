import { Slide } from '@material-ui/core';
import React, { FC, useState, useEffect } from 'react';
import BreakPoint from '../break-point'
import { IHero } from '../../components/home/hero';
import { useNavigate } from 'react-router-dom';
import './index.scss';

interface IProps {
    HeroInfo: Array<IHero>;
    showArrows?: boolean;
    isGallery?: boolean;
};

const Carousel:FC<IProps> = (props) => {

    const { showArrows = false, isGallery = false, HeroInfo } = props;
    const [ index, setIndex ]= useState(0);
    var currVid = HeroInfo[index]!?.info!?.replace('upload','upload/q_auto:low');
    const navigate = useNavigate();

    useEffect(() => {
        let timer : NodeJS.Timeout
        timer = setTimeout(() => {
            if(index === HeroInfo.length - 1 ){
                setIndex(0)
            }else{
                setIndex(index+1)
            }
        }, 9000);

        return(()=>{clearTimeout(timer)})
    },[index, HeroInfo]);

    return(
        <div className='main-carousel'>

            {index > 0 && showArrows &&
                <div className='left-arrow' onClick={() => setIndex(index-1)}>
                    <i className='fa fa-arrow-left'></i>
                </div>
            }

            {!isGallery &&
                <BreakPoint>
                    <div className='info'>
                        <Slide in={true} direction='right' timeout={1500} key={index}>
                            <div>
                                <h1>Chithub</h1>  
                                <h2>{HeroInfo[index]!?.info}</h2>  
                                <button className="btn-style" onClick={() => navigate(HeroInfo[index]!?.url)}>
                                    {HeroInfo[index]!?.cta}&nbsp;&nbsp;
                                    <i className="fas fa-arrow-right" />
                                </button> 
                            </div>     
                        </Slide>
                    </div>
                </BreakPoint>
            }

            {isGallery &&
                <div className='image'>
                    {checkIfVid(HeroInfo[index]!?.info)?
                        <video controls>
                            <source src={currVid} />
                        </video>
                        :
                        <img src={HeroInfo[index]!?.info} alt='carousel-img'/>
                    }
                </div>
            }

            { HeroInfo!?.length > 0 && index < HeroInfo!?.length - 1 && showArrows &&
                <div className='right-arrow' onClick={() => setIndex(index+1)}>
                <i className='fa fa-arrow-right'></i>
            </div>
            }


        </div>
    )
};

export default Carousel;

const checkIfVid =  <R,>( item: R ): boolean => {
    if (typeof item === 'string' && item.length > 0) {
        if( item.substring(item.lastIndexOf('.')).includes('mp4') ) {
            return true
        }else{
            return false
        }
    }return false
};

