import React from 'react';
import SolutionUtil from '../../utils/solutions';
import "./index.scss";
import Page from '../../utils/page';
import Carousel from '../../utils/carousel';
import { HeroInfo } from './hero';
import About from '../../utils/about'
import Purpose from '../../utils/purpose'
import Contact from '../../utils/contact-us'
import BreakPoint from '../../utils/break-point';

interface IProps { }

const Home: React.FC<IProps> = (props) => {
	const heroVideo = 'https://res.cloudinary.com/dv2i1uzlr/video/upload/v1627037261/hero_video_cps2v1.mp4'.replace('upload','upload/q_auto:low');

	return (
		<Page>
			<div className='home-page' >

				<div className="home-container">
					<video autoPlay muted loop width="100%">
						<source src={heroVideo} type="video/mp4" />
					</video>
					<div className="dark-cover"/>
					<div className="carousel-container" >
						<Carousel HeroInfo={HeroInfo} />
					</div>					
				</div>

				<BreakPoint>
					<About />

					<SolutionUtil isHomePage />

					<Purpose />

					<Contact />
				</BreakPoint>

			</div>
		</Page>
	);
}

export default Home;