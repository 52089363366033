import { Url } from "../../enums/route";

export interface IHero{
    info: string;
    cta:string;
    url: string;
}

export const HeroInfo: Array<IHero> = [
    {
        info: 'Transforming Traffic Enforcement and Penalty Management in Nigeria ',
        cta : 'More About Us',
        url: Url.about
    },
    {
        info: 'Our traffic enforcement solution is bespoke and developed using IoT, AI and computer vision technologies. ',
        cta : 'More About Us',
        url: Url.about
    },
    {
        info: 'ChitHub is providing traffic enforcement agencies at both state and federal government levels with end-to-end traffic enforcement and penalty management solution to keep cities safe and promote the right behaviors in road users.',
        cta : 'Learn More',
        url: Url.about
    },
]