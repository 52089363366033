import { ReactNode } from 'react';
import purpose from '../../extras/images/purpose.jpg';
import mission from '../../extras/images/mission.jpg';
import { VisionIcon } from '../../extras/images/VisionIcon';
import { CoreValuesIcon } from '../../extras/images/CoreValuesIcon';

type Align = 'left' | 'right'

interface IASection {
    id: number;
    header: string;
    content?: ReactNode;
    isList?: boolean;
    list?: Array<string>;
    image?: ReactNode;
    align?: Align
};

export const AboutSections : Array<IASection> = [
    {
        id: 1,
        header: 'Our Purpose',
        content: "CTSMC team is driven by the need to provide solutions that will enable government agencies keep our cities safer and instill the right behaviors in road users. We aim to grow the capacities of traffic enforcement agencies by providing accurate detection and actionable data for the purpose of enforcing compliance.",
        image: purpose
    },
    {
        id: 2,
        header: 'Our Vision',
        content: "To be the leading company in Nigeria and Africa providing technology enabled enforcement solutions that help smart cities improve road use behavior and create safer roads",
        align: 'right',
        image: VisionIcon(),
    },
    {
        id: 3,
        header: 'Mission Statement',
        content: "To create strong partnership with government and relevant agencies in Nigeria and Africa and with technology partners across the globe to provide indigenous content essential for technological development required when implementing electronic traffic enforcement solution for creating smart cities, promoting the right behaviors in motorists and other road users.",
        image: mission
    },
    {
        id: 4,
        header: 'Our core values',
        isList: true,
        list: [
            "Innovation: we are committed to finding better ways of doing business every day. We believe in adaptive and multi-focus thinking. We embrace change for the growth opportunities it brings.",

            "Integrity: We are reasonable professionals who believe that honesty, integrity and fair play are key to delivering sustainable benefits to our stakeholders",

            "Team Work: We believe in collaborative effort to achieve our goals in the most effective and efficient way as teamwork promotes strong working relationships and improves productivity",

            "Tenacity, Resilience and Energy: We are a tenacious team committed to delivering distinctive value and change to our society",

            "Respect (Diversity & Inclusion): We believe in the platinum rule - ’treat others as they wish to be treated’"
        ],
        align: 'right',
        image: CoreValuesIcon()
    },
]