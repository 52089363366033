import React, { useState } from 'react';
import { Slide, makeStyles, Button } from '@material-ui/core';
import Modal from '../../utils/modal';
import ContactForm from './form';
import Page from '../../utils/page';
import './index.scss';

interface IProps {}

const Contact: React.FC<IProps> = (props) => {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };

    const useStyles = makeStyles((theme) => ({
        image: {
            backgroundImage: `url(${require("../../extras/images/contact-us.jpg")})`,
            backgroundRepeat: 'no-repeat',
            backgroundBlendMode: 'multiply',
            backgroundColor: 'rgb(97, 97, 97)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
    }));

    const classes = useStyles();

    return (
        <Page>
            <div className={`${classes.image}`}>
                <Slide in={true} direction='right' timeout={1500} >
                    <div className='contact-page'>
                        <div style={{ textAlign: 'center' }}>
                            <h1 style={{ fontSize: '3em' }}>We'd love to hear from you</h1>
                            <p>Any question you have, our team is ready to answer all!</p>
                        </div>
                        <Button variant="contained" color="primary" onClick={handleShowModal}>
                            Contact Us
                        </Button>
                    </div>
                </Slide>
            </div>
            <Modal show={showModal} handleClose={handleHideModal}>
                <ContactForm />
            </Modal>
        </Page>
    );
};

export default Contact;