import React from "react";
import { Slide, makeStyles, Container } from "@material-ui/core";
import "./index.scss";
import Page from "../../utils/page";
import { AboutSections } from './sections';
import N_A from '../../extras/images/N_A.jpg';
import BreakPoint from "../../utils/break-point";

const About: React.FC = () => {
	const useStyles = makeStyles((theme) => ({
		image: {
			backgroundImage: `url(${require("../../extras/images/about-us.png")})`,
			backgroundRepeat: "no-repeat",
			backgroundBlendMode: "multiply",
			backgroundColor: "rgb(80, 80, 80)",
			backgroundSize: "cover",
			backgroundPosition: "center",
		},
	}));

	const classes = useStyles();
	return (
		<Page>
			<div className="about-page">
				<div className={`jumbotron ${classes.image}`}>
					<Slide in={true} direction="right" timeout={1500}>
					<div className="purpose-content">
						<p>
							"We are a <b>knowledge company</b> whose aim is to grow the capacities of traffic enforcement agencies by providing accurate detection and actionable data for the purpose of enforcing compliance."
						</p>

						<div className="credit-purpose">
							<p className="credit-name-purpose">CEO</p>
							<p className="credit-title-purpose">Chithub</p>
						</div>

					</div>
					</Slide>
				</div>
				<BreakPoint>
					<>
						<div className="about-intro">
							<p>
								<b>ChitHub Traffic System Management Company Ltd (CTSMC)</b>, is an innovative smart city technology company registered in Nigeria with expertise in implementing road use enforcement solutions using artificial intelligence and Internet of Things (IoT) technology. 
							</p>
						</div>

						{
							AboutSections.map(section => {
								const { header, content, list, isList, id, align, image } = section
								return(
									<Container className='section ' key={id} 
									style={{flexDirection: window.innerWidth > 768 ? align === 'right' ? 'row': 'row-reverse' : 'column' }}>
										<div className="section-image">
											{typeof image === 'string' ?
												<img src={image || N_A} alt=":(" />
												:
												image
											}
										</div>
										<div className= 'section-text'>
											<h1>{header}</h1>

											{!isList ? 
												<p>{content}</p>
												:
												<ul>
													{
														list!?.map((item, index) => {
															return(
																<li key={index}>
																	{item}
																</li>
															)
														})
													}
												</ul>
											
											}
										</div>
									</Container>
								)
							})
						}
					</>
				</BreakPoint>
			</div>
		</Page>
	);
};

export default About;