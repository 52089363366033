import React from 'react'
import { Button } from "@material-ui/core";
import loader from '../../extras/loader/loaderwhite.svg'
import './index.scss'

interface IProps {
    isLoad : boolean;
    title : string | JSX.Element;
    onClick : React.MouseEventHandler<HTMLButtonElement> | undefined;
    isSuccess : boolean;
}

export const ButtonRequest = ( props : IProps ) => {
    
    const { isLoad, title, onClick, isSuccess } = props

    return(
        <Button variant="contained" color="primary" className="btn-request" onClick={onClick}>
            { isLoad ? <img src={loader} alt="loading" className="icon"/> : title }
            { isSuccess ? (<i className="fas fa-check ir" /> ) : "" }
        </Button> 
    )
}