import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Url } from '../../enums/route';
import aboutlanding from '../../extras/images/aboutlanding.jpg'
import "./index.scss";

const About = () => {

    const navigate = useNavigate();

    return (
        <div className="about-content">

            <div className='header-about'>
                <h1>About Us:</h1>
                <b>Transforming Traffic Enforcement and Penalty Management in Nigeria </b>
            </div>

            <div className="about-decription">
                <div className="left-section about-description-section">
                    <p>
                    Traffic enforcement agencies in Nigeria are faced with a plethora of challenges ranging from existing manual processes, unavailability of resources (human and capital), inadequate infrastructures, and lack of proper technology. Based on these limitations some motorists fail to obey simple traffic rules which in some cases lead to fatality.
                    </p><br />
                    <p>
                    ChitHub is providing traffic enforcement agencies at both state and federal government levels with an end-to-end traffic enforcement and penalty management solution to keep cities safe and promote the right behaviors in road users and security.
                    </p><br/>
                    <p>
                    Unlike other traffic violation management solutions, ChitHub solution suite comprises of manned and unmanned devices combined with smart cameras and Artificial Intelligence (AI) that allows 24/7 surveillance road networks and real-time data synchronization.
                    </p>

                    <button className="btn-style" onClick={() => navigate(Url.about)}>
                        Learn More&nbsp;&nbsp;
                        <i className="fas fa-arrow-right" />
                    </button> 
                </div>
                <div className="right-section about-description-section">
                    <img src={aboutlanding} alt="traffic" />
                </div>
            </div>

        </div>
    );
}

export default About;