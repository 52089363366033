import React from 'react';
import { Card, CardActionArea, CardContent, Box } from '@material-ui/core';
import { solutionsArray } from './solutions';
import N_A from '../../extras/images/eTraffika.png';
import "./index.scss";
import { useNavigate } from 'react-router-dom';
import { Url } from '../../enums/route';

interface IProps {
    isHomePage? : boolean;
 }

const SolutionUtil: React.FC<IProps> = props => {
    const { isHomePage } = props;
    const navigate = useNavigate();

    return (
        <div>

            <Box className="our-solutions" id="our-solutions">
                {isHomePage && 
                    <div className='header'>
                        <h1 style={{ textAlign:'left', marginTop: '50px' }}>
                            Our Solutions
                        </h1>
                    </div>
                }

                <Box className='card-section'>
                    {solutionsArray.slice(0, isHomePage ? 3 : solutionsArray.length ).map((solution, idx) => (
                        <Card className={`card`} key={idx} onClick={() => navigate(solution.linkTo) }>
                            <CardActionArea>
                                <div className='card-image'>
                                    <img alt={solution.alt} src={solution.img || N_A}/>
                                </div>
                                <CardContent>
                                    <div className='card-header'>
                                        <h2 className='card-label'> {solution.mainTitle} </h2>
                                    </div>
                                    <p className='card-text'> {solution.content} </p>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    ))}
                </Box>
                <div style={{marginTop:"20px"}}>
                    {isHomePage &&
                        <button className="btn-style" onClick={() => navigate(Url.solutions)}>
                            Know More&nbsp;&nbsp;
                            <i className="fas fa-arrow-right" />
                        </button> 
                    }
                </div>
            </Box>
        </div>
    );
}

export default SolutionUtil;