import React, { useState, useEffect } from 'react';
import { Slide, makeStyles } from '@material-ui/core';
import SolutionUtil from '../../utils/solutions';
import Page from '../../utils/page';
import './index.scss';
import { ISolution } from '../../interfaces/ISolution';
import { solutionsArray } from '../../utils/solutions/solutions';
import SolnMoreDetails from './more-details';
import { useParams } from 'react-router-dom';
import eT from '../../extras/images/etraffika.jpg';
import BreakPoint from '../../utils/break-point';

const Solutions: React.FC = () => {

    const useStyles = makeStyles((theme) => ({
        image: {
            backgroundImage: `url(${require("../../extras/images/solutions.jpg")})`,
            backgroundRepeat: 'no-repeat',
            backgroundBlendMode: 'multiply',
            backgroundColor: 'grey',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        }
    }));
    const [ isMoreOption, setIsMoreOption ] = useState( false );
    const [ solnObj, setSolObj ] = useState({} as ISolution);
    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        if(id) {
            setIsMoreOption(true)
            setSolObj(solutionsArray.filter(i => i.id === id)[0])
        }else {
            setIsMoreOption(false)
            setSolObj({} as ISolution)
        }
    },[id])

    const classes = useStyles();
    return (
        <Page>
            {!isMoreOption ? 
                <div className='solutions-page'>
                    <div className={`jumbotron ${classes.image}`}>
                        <BreakPoint>
                            <Slide in={true} direction='right' timeout={1500} >
                                <h1 style={{ fontSize: '3em' }}>Our Solutions</h1>
                            </Slide>
                        </BreakPoint>
                        {/* <div className="jumbotron-pop">
                            <h1>We offer the following unique solutions</h1>
                        </div> */}
                    </div>
                    <BreakPoint>
                        <>
                            <div className='solution-header'>
                                <h1>ETraffika</h1>
                            </div>
                            <div className="solutions-intro">
                                
                                <div className="info">
                                    <p>
                                        The Integrated Traffic Enforcement & Penalty Management System (eTraffika) solution is an end-to-end solution composed of Internet of Thing (IoT), cloud computing, mobile computing, artificial intelligence and computer vision to create a state-of-the-art robust electronic enforcement system.  The solution has the capabilities to detect different types of road use violations real-time, notify drivers of their violations, provide authorities with detailed violation reports and manage vehicle data collections. 
                                    </p>
                                    <p>
                                        Unlike other traffic violation management solutions, our product comprises of manned and unmanned devices combined with smart cameras and Artificial Intelligence (AI) that allows the 24/7 surveillance road networks and real-time data synchronization
                                    </p>
                                </div>
                                <div className="image">
                                    <img src={eT} alt="eTraffika" />
                                </div> 
                            </div>
                            <div style={{ color: '#000' }}>
                                <div>
                                    <h1>Services ETraffika provides</h1>
                                    <span>The eTraffika suite comprises of multiple microsystems which are loosely coupled to form a robust solution that caters for the entire enforcement and penalty lifecycle</span>
                                </div>
                                
                                <SolutionUtil />
                            </div>
                        </>
                    </BreakPoint>
                </div>
                :
                <SolnMoreDetails solutionInfo={solnObj} />
            }
            
        </Page>
    );
}

export default Solutions;