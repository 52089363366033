import React from 'react';
import { Link, Typography } from '@material-ui/core';

export const Copyright = () => {
    return (
        <Typography variant="body2" align="center" style={{color: '#fff'}}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.ssschithub.com">
                ChitHub Traffic Management System Company LTD
			</Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}