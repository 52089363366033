import React, { ReactNode } from 'react';
import {Navbar, 
  TopNavBar, 
  Footer } from '../../components/navigations/index';

interface IProps {
  hideNavbar?: boolean;
  hideFooter?: boolean;
  children: ReactNode
}

const Page: React.FC<IProps> = (props) => {
  const { hideNavbar, hideFooter } = props;
  
  return (
    <div>
      <TopNavBar />
      {!hideNavbar && <Navbar />}
      <div className='page-body'>{props.children}</div>
      {!hideFooter && <Footer  />}
    </div>
  );
};

export default Page;
