import React, { FC } from 'react';
import "./index.scss";
import BreakPoint from '../break-point'

const Purpose: FC = () => {

    return (
        <div className="purpose-main">

            <div className="purpose-bg-dark-filter" />

            <BreakPoint>

                <div className="purpose-content">

                    <p>
                        "We are a <b>knowledge company</b> whose aim is to grow the capacities of traffic enforcement agencies by providing accurate detection and actionable data for the purpose of enforcing compliance."
                    </p>

                    <div className="credit-purpose">
                        <p className="credit-name-purpose">CEO </p>
                        <p className="credit-title-purpose">Chithub</p>
                    </div>
                    
                </div>

            </BreakPoint>

        </div>
    );
}

export default Purpose;