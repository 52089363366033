import React from 'react';
import "./index.scss";
import { ISolution } from '../../../interfaces/ISolution';
import { makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Url } from '../../../enums/route';

interface IProps { 
    solutionInfo: ISolution
}

const SolnMoreDetails: React.FC<IProps> = (props) => {

    const { solutionInfo } = props;
	const navigate = useNavigate();
	const useStyles = makeStyles((theme) => ({
        image: {
            backgroundImage: `url(${solutionInfo.img})`,
            backgroundRepeat: 'no-repeat',
            backgroundBlendMode: 'multiply',
            backgroundSize: 'cover',
			backgroundColor: 'grey',
            backgroundPosition: 'center',
        }
    }));
	const classes = useStyles();
	console.log(solutionInfo!?.imgPath)

	return (
		<div className='solutions-more-details' >

			<div className={`details-hero ${classes.image}`}>
				<div className="hero-content" >
					<h1>{solutionInfo!?.mainTitle}</h1>
				</div>					
			</div>
			
			<div className="detail-info">
				<h1>{solutionInfo!?.mainTitle}</h1>
				<p>{solutionInfo!?.content}</p><br />

				<div className="more-info">
					<p>{solutionInfo!?.moreDetails}</p>
					{solutionInfo!?.detailList!?.length > 0 &&
						<ul>
							{
								solutionInfo!?.detailList!?.map((item, index) => {
									return(
										<li key={index}>
											{item}
										</li>
									)
								})
							}
						</ul>
					}
					
				</div>
				<button className="btn-style" onClick={() => navigate(Url.solutions)}>
					View other solutions&nbsp;&nbsp;
					<i className="fas fa-arrow-right" />
				</button> 
			</div>

			

		</div>
	);
}

export default SolnMoreDetails;