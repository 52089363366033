import {
	AXIOS_REQUEST, AXIOS_REQUEST_FAILURE, AXIOS_REQUEST_SUCCESS,
} from "../actions/types";

const initialState = {
	authenticated: false,
	loading: false,
	action: '',
	errors: '',
	data: [],
};

const adminReducer = function (state = initialState, action: any) {
	switch (action.type) {
		case AXIOS_REQUEST:
			return {
				...state,
				loading: true,
				action: action.payload
			};
		case AXIOS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
				errors: ''
			};
		case AXIOS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
				data: [],
				errors: action.payload
			};
		default:
			return state;
	};
};

export default adminReducer;